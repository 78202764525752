import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  collection,
  getDocs,
  query,
  where,
  documentId,
} from "firebase/firestore";
import { db } from "../../../firebase-config";
import sideTextLogoWhite from "../../../Images/sideLogoWhite2.svg";
import { Formik, Form, Field } from "formik";
import { useLocation } from "react-router-dom";
import axios from "axios";
import classNames from "classnames";

import { XMarkIcon } from "@heroicons/react/24/outline";

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const SubscriptionForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const appUrl = process.env.APP_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!stripe || !elements) {
      setError("Stripe or Elements not loaded");
      return;
    }

    setLoading(true);

    const paymentElement = elements.getElement(PaymentElement);
    if (!paymentElement) {
      setError("Payment Element not found.");
      setLoading(false);
      return;
    }

    try {
      const { error: setupError, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${appUrl}?setup_intent=${clientSecret}`,
        },
      });

      if (setupError) {
        setError(setupError.message);
        return;
      }

      // If we get here without a redirect, something unexpected happened
      setError("An unexpected error occurred. Please try again.");
    } catch (err) {
      setError(err.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='m-6'>
      {clientSecret ? (
        <form onSubmit={handleSubmit}>
          <h1 className='my-2'>Card information</h1>
          <PaymentElement />
          <h1 className='my-2'>Billing address</h1>
          <AddressElement options={{ mode: "billing" }} />

          <button
            type='submit'
            disabled={!stripe || loading}
            className='bg-primary hover:bg-primary-light hover:text-dark-text text-light-text font-bold py-2 px-4 rounded mt-6 w-full'
          >
            {loading ? "Loading..." : "Subscribe"}
          </button>
          <p className='mx-4 mt-4 text-sm'>
            By confirming your subscription, you allow Mental Health Connections
            to charge you for future payments in accordance with their terms.
            You can always cancel your subscription.
          </p>
        </form>
      ) : (
        <p>Loading payment form...</p>
      )}
    </div>
  );
};

const SubscriptionPage = () => {
  const location = useLocation();
  const { plan, user } = location.state || {};
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [priceInfo, setPriceInfo] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [couponValid, setCouponValid] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [tax, setTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [percentOff, setPercentOff] = useState(0);
  const [planInfo, setPlanInfo] = useState(null);

  const backendUrl = process.env.BACKEND_URL;

  useEffect(() => {
    async function getStripeCustomerId(customerQuery) {
      try {
        // Wait for the documents to be fetched
        let customerSnapshot = await getDocs(customerQuery);

        // Check if there are any documents in the snapshot
        let attempts = 0;
        while (customerSnapshot.empty && attempts < 8) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          customerSnapshot = await getDocs(customerQuery);
          attempts++;
        }

        if (customerSnapshot.empty) {
          console.error("No matching documents found.");
          return null; // Handle this case as needed, or return an appropriate value
        }

        // Wait for the stripeId to be extracted
        const stripeCustomerId = customerSnapshot.docs[0].data().stripeId;
        // Return the stripeCustomerId
        return stripeCustomerId;
      } catch (error) {
        console.error("Error retrieving stripeId:", error);
        return null; // Handle or return an appropriate value in case of an error
      }
    }

    async function getPlanInfo(planName) {
      console.log(planName);
      const planQuery = query(
        collection(db, "products"),
        where("name", "==", planName)
      );
      const planSnapshot = await getDocs(planQuery);

      if (planSnapshot.empty) {
        console.error("No matching documents found.");
        return null; // Handle this case as needed, or return an appropriate value
      }
      const productInfo = planSnapshot.docs[0].data();
      // get the product ID
      const productId = planSnapshot.docs[0].id;

      // Get the price information
      const priceQuery = query(collection(db, "products", productId, "prices"));
      const priceSnapshot = await getDocs(priceQuery);
      // Get the price information
      const priceInfo = priceSnapshot.docs[0].data();
      const priceId = priceSnapshot.docs[0].id;

      return { productId, priceInfo, productInfo, priceId };
    }

    const fetchClientSecret = async () => {
      try {
        // Fetch customer ID from your backend
        const customerQuery = query(
          collection(db, "customers"),
          where(documentId(), "==", user)
        );
        //TODO: fix the time delay
        const planInfo = await getPlanInfo(plan);
        setPlanInfo(planInfo);
        setPriceInfo(planInfo.priceInfo);
        setProductInfo(planInfo.productInfo);
        setTax(((planInfo.priceInfo.unit_amount / 100) * 0.09).toFixed(2));
        setTotal((planInfo.priceInfo.unit_amount / 100).toFixed(2));
        setStripeCustomerId(await getStripeCustomerId(customerQuery));
      } catch (error) {
        console.error(
          "Error fetching client secret or updating customer:",
          error
        );
      }
    };

    fetchClientSecret();
  }, [user]);

  useEffect(() => {
    const createSubscription = async () => {
      if (!stripeCustomerId) return;
      try {
        // Call backend to create a subscription and get the clientSecret
        const response = await fetch(`${backendUrl}create-subscription`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            customerId: stripeCustomerId,
            priceId: planInfo.priceId, // Replace with the price ID from your Stripe Dashboard
            couponCode: couponCode,
          }),
        });
        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error(
          "Error fetching client secret or updating customer:",
          error
        );
        if (error === "Customer already has a subscription in trail period") {
          console.log("Customer already has a subscription in trail period");
          //
        }
      }
    };
    createSubscription();
  }, [stripeCustomerId]);

  return (
    <div className='h-screen'>
      {/* title div */}

      <div className='h-full flex flex-col md:flex-row justify-between'>
        {productInfo ? (
          <div className='divide-y-2 divide-light-text/25 h-fit sm:h-full w-1/2 p-8 bg-primary text-light-text'>
            <img
              className='h-32 w-auto pt-4'
              src={sideTextLogoWhite}
              alt='logo'
            />

            <div className='mb-4 pt-4'>
              <h2>Subscribe to {productInfo.name}</h2>
              <div className='flex flex-row my-2 align-middle'>
                <h1 className='text-light-text text-4xl'>14 days free</h1>
              </div>
              <div className='flex flex-row'>
                <h1 className=''>
                  Then CA${(priceInfo.unit_amount / 100).toFixed(2)} per{" "}
                  {priceInfo.interval}
                </h1>
              </div>
            </div>
            <div className='mb-4 pt-4'>
              <div className='flex justify-between'>
                <h2>{productInfo.name}</h2>
                <h2>14 days free</h2>
              </div>
              <p className='m-4'>{productInfo.description}</p>
              <h2>
                CA${(priceInfo.unit_amount / 100).toFixed(2)}/{" "}
                {priceInfo.interval} after
              </h2>
            </div>
            <div className='mb-4 pt-4'>
              <div className='flex flex-row justify-between mb-4'>
                <h2>Subtotal</h2>
                <p>CA${(priceInfo.unit_amount / 100).toFixed(2)}</p>
              </div>

              {coupon ? (
                <div>
                  <Formik
                    initialValues={{ couponCode: "" }}
                    onSubmit={async (values) => {
                      // handle coupon application
                      try {
                        const { data } = await axios.get(
                          `${backendUrl}verify-coupon/${values.couponCode}`
                        );
                        console.log(data);
                        // set coupon to true and calculate the new total
                        if (data.isValid === true) {
                          console.log("Coupon is valid" + values.couponCode);
                          const response = await fetch(
                            `${backendUrl}add-coupon-subscription`,
                            {
                              method: "POST",
                              headers: { "Content-Type": "application/json" },
                              body: JSON.stringify({
                                customerId: stripeCustomerId,
                                couponCode: values.couponCode,
                              }),
                            }
                          );
                          setCouponValid(data.isValid);
                          setCouponCode(values.couponCode);
                          setPercentOff(data.details.percentOff);
                          setTotalDiscount(
                            (priceInfo.unit_amount / 100) *
                              (data.details.percentOff / 100)
                          );
                          setTotal(
                            (
                              (priceInfo.unit_amount / 100) *
                              (1.0 - data.details.percentOff / 100)
                            ).toFixed(2)
                          );
                        } else {
                          setCouponValid("invalid");
                        }
                        console.log(couponValid);

                        return data;
                      } catch (error) {
                        setCouponValid("invalid");
                        console.error("Error:", error);
                        return null;
                      }
                    }}
                  >
                    {({ setFieldValue }) => (
                      <Form>
                        {couponValid === false || couponValid === "invalid" ? (
                          <div>
                            <Field
                              type='text'
                              name='couponCode'
                              id='couponCode'
                              placeholder='Enter your code'
                              className={classNames(
                                "border-2 border-dark-text text-dark-text rounded-lg p-2 m-2",
                                {
                                  "text-red-500": couponValid === "invalid",
                                }
                              )}
                              onChange={(e) => {
                                // Reset couponValid state on input change
                                setCouponValid(false);
                                setFieldValue("couponCode", e.target.value);
                              }}
                            />
                            {couponValid === "invalid" ? (
                              <p className='text-light-text'>
                                Invalid coupon code
                              </p>
                            ) : null}
                            <button
                              type='submit'
                              className='bg-black text-white p-2 rounded-lg'
                            >
                              Apply
                            </button>
                          </div>
                        ) : (
                          <div>
                            <div className='flex flex-row justify-between'>
                              <div className='flex flex-row bg-black p-2 rounded-lg align-middle'>
                                <p className='text-light-text'>
                                  {couponCode} applied
                                </p>
                                <button
                                  type='button'
                                  onClick={() => {
                                    setCouponValid(false);
                                    setTotal(
                                      (priceInfo.unit_amount / 100).toFixed(2)
                                    );
                                  }}
                                  className='bg-black text-white pl-2'
                                >
                                  <XMarkIcon className='h-4 w-4' />
                                </button>
                              </div>
                              <div>
                                <p>-CA${totalDiscount}</p>
                              </div>
                            </div>
                            <p>{percentOff}% off</p>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : (
                <button
                  className='bg-black text-white p-2 rounded-lg'
                  onClick={() => setCoupon(true)}
                >
                  Add promotion code
                </button>
              )}
            </div>
            {/* <div className='mb-4 pt-4'>
              <div className='flex flex-row justify-between'>
                <h2>Tax</h2>
                <p>{tax}</p>
              </div>
            </div> */}
            <div className='mb-4 pt-4'>
              <div className='flex flex-row justify-between'>
                <h2>Total after trial</h2>
                <p>CA${total}</p>
              </div>
              <div className='font-semibold flex flex-row justify-between'>
                <h2>Total due today</h2>
                <p>CA$0.00</p>
              </div>
            </div>
          </div>
        ) : null}
        <div className='w-1/2'>
          {clientSecret ? (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <SubscriptionForm clientSecret={clientSecret} />
            </Elements>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
