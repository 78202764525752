import React, { useEffect, useState, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import { db, auth } from "../../../firebase-config";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  setDoc,
  where,
  query,
  onSnapshot,
  updateDoc,
  addDoc,
  limit,
} from "firebase/firestore";
import Overall_week_icon_green from "../../../Images/Overall_week_icon_green.svg";
import { onAuthStateChanged } from "firebase/auth";
import { Formik, Form, Field } from "formik";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import categories from "./common/healthCircleDef";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import DaysInTrailBanner from "./Banners/DaysInTrail";

export default function MainPage(props) {
  const [showGoal, setShowGoal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [userInfo, setUserInfo] = useState({});
  const [events, setEvents] = useState([]);
  const [numberOfAssessments, setNumberOfAssessments] = useState(0);
  const [nextAchievement, setNextAchievement] = useState(0);
  const [nextAssessment, setNextAssessment] = useState(new Date());
  const [assessmentCountDown, setAssessmentCountDown] = useState(0);
  const [assessmentOpen, setAssessmentOpen] = useState(false);
  const [showEventArray, setShowEventArray] = useState(
    Array(events.length).fill(false)
  );
  const cancelButtonRef = useRef(null);
  const [toFocus, setToFocus] = useState([]);
  const [sentInvite, setSentInvite] = useState(false);

  const eventCount = 3;
  let displayEvents = 0;

  const makeNewForm = async () => {
    const today = new Date();

    if (today < nextAssessment) {
      return;
    }

    // check if there was an assessment completed between last saturday and today
    // if there was use that assessment
    // if there was not then create a new assessment
    const lastSaturday = new Date();
    lastSaturday.setDate(today.getDate() - ((today.getDay() + 1) % 7));
    lastSaturday.setHours(0, 0, 0, 0);
    today.setHours(24, 0, 0, 0);

    MainPage.propTypes = {
      userID: PropTypes.string.isRequired,
      information: PropTypes.object.isRequired,
    };

    const q = query(
      collection(db, "User/" + props.userID + "/Assessments"),
      where("date", ">", lastSaturday),
      where("date", "<", today)
    );
    const querySnapshot = await getDocs(q, limit(2));

    if (querySnapshot.docs.length === 0) {
      // make a new form
      // user info is in userInfo
      const assessmentsCollection = collection(
        db,
        "User/" + props.userID + "/Assessments"
      );
      const assessmentsDoc = doc(
        assessmentsCollection,
        generateRandomString(20)
      );
      const currentDate = new Date();
      // Create the first assessment document
      await setDoc(assessmentsDoc, {
        completed: {
          Add_connections_to_your_calendar: "",
          Create_update_Connections: "",
          Overall_Mental_Health: "",
          Populate_your_Health_Circles: "",
        },
        connections: "",
        date: currentDate,
        lastPage: "overall",
        assessments: "",
        nextPage: "addItems",
        overall_mental_health: 5,
        nine_circles: {
          healthy_nutrition_snacks: 100,
          hobbies: 100,
          laughter_makes_you_smile: 100,
          movement_activity: 100,
          outdoors_nature: 100,
          planning_looking_forward_to: 100,
          self_care: 100,
          sleep_recovery: 100,
          social_interaction: 100,
        },
      });
    }
  };

  // used to create the names for documents
  function generateRandomString(length) {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }
    return result;
  }

  const sendInvite = (email) => {
    let payload = {
      to: email.inviteEmail,
      template: {
        name: "landing_page_invite_email",
        data: {
          invitedPerson: email.inviteEmail,
          MHCUser: "a Mental Health Connections User",
        },
      },
    };
    addDoc(collection(db, "contact_us_email"), payload);
  };

  useEffect(() => {
    async function getUpcomingEvents() {
      // get the user information from the database
      if (props.userID) {
        const calendarDoc = doc(
          db,
          "User/" + props.userID + "/Calendar",
          "Calendar"
        );
        const calendarSnap = await getDoc(calendarDoc);
        const eventsList = [];

        if (calendarSnap.exists()) {
          for (const element of calendarSnap.data().events) {
            if (element.startTime.seconds > new Date() / 1000) {
              eventsList.push(element);
            }
          }
          if (eventsList.length === 0) {
            // there are no events planned
            setEvents(["No upcoming events"]);
          } else {
            setEvents(eventsList);
          }
        } else if (calendarSnap.data().events.length === 0) {
          // doc.data() will be undefined in this case
          setEvents(["Go to the calendar page to start adding events"]);
        } else {
          setEvents(["No upcoming events"]);
        }
      }
    }
    // get a list of events coming up

    getUpcomingEvents();
  }, [props.information]);

  // to get the user id and check if a weekly assessment can be created
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const collectionRef = collection(db, "User");
        const q = query(collectionRef, where("uuid", "==", user.uid));
        let userDocID = "";

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const user = [];
          querySnapshot.forEach((doc) => {
            user.push(doc.data());
            userDocID = doc.id;
          });
          setUserInfo(user[0]);
          saturdayCheck(userDocID);
          getStats(userDocID);
          initUpcomingEvents(userDocID);
          getNumberOfAssessments(userDocID);
          getAchievementData(user[0].number_completed);
        });

        return () => unsubscribe();

        // get the connections and the Calendar events
      } else {
        // No user is signed in. Redirect to login page
        navigator.push("/loginPage");
      }
    });
    async function initUpcomingEvents(userDocID) {
      // get the user information from the database
      if (userDocID) {
        const calendarDoc = doc(
          db,
          "User/" + userDocID + "/Calendar",
          "Calendar"
        );
        const calendarSnap = await getDoc(calendarDoc);
        const eventsList = [];

        if (calendarSnap.exists()) {
          for (const element of calendarSnap.data().events) {
            if (element.startTime.seconds > new Date() / 1000) {
              eventsList.push(element);
            }
          }
          if (eventsList.length === 0) {
            // there are no events planned
            setEvents(["No upcoming events"]);
          } else {
            setEvents(eventsList);
          }
          if (calendarSnap.data().events.length === 0) {
            // doc.data() will be undefined in this case
            setEvents(["Go to the calendar page to start adding events"]);
          }
        } else {
          setEvents(["No upcoming events"]);
        }
      }
    }

    async function saturdayCheck(userID) {
      // go through all of the assessments and check if one was completed between last saturday and today
      // if there was one completed the set the next assessment to be the next saturday
      // if there was not one completed then set the assessment to open
      const today = new Date();
      const lastSaturday = new Date();
      lastSaturday.setDate(today.getDate() - ((today.getDay() + 1) % 7));
      lastSaturday.setHours(0, 0, 0, 0);
      today.setHours(24, 0, 0, 0);

      const q = query(
        collection(db, "User/" + userID + "/Assessments"),
        where("date", ">", lastSaturday),
        where("date", "<", today)
      );
      const querySnapshot = await getDocs(q);
      const assessmentList = querySnapshot.docs.map((doc) => doc.data());
      if (assessmentList.length >= 1) {
        // check if the assessment was marked as completed
        let completed = true;
        const completedList = Object.values(assessmentList[0].completed);
        for (const element of completedList) {
          if (element == "") {
            completed = false;
          }
        }

        if (completed) {
          // set the next assessment to be the next saturday
          const nextSaturday = new Date();
          nextSaturday.setDate(
            today.getDate() + ((6 - today.getDay() + 7) % 7)
          );
          const difference = nextSaturday.getDay() - today.getDay() + 1;
          if (difference < 0) {
            difference = 7;
          }

          setAssessmentCountDown(difference);
          setNextAssessment(nextSaturday);
        } else {
          setNextAssessment(new Date());
          setAssessmentOpen(true);
        }
      } else {
        // set the next assessment to be open

        setNextAssessment(new Date());
        setAssessmentOpen(true);
      }
    }

    async function getStats(userDocID) {
      const statsList = doc(db, "User/" + userDocID + "/Stats", "Stats");
      const statsListSnap = await getDoc(statsList);
      if (statsListSnap.exists()) {
        const statsListSnapData = statsListSnap.data();
        if (statsListSnapData.total_completed === 0) {
          setToFocus(["More information needed"]);
          setIsLoading(false);
          return;
        }
        let min = statsListSnapData.sections["movement_activity"];
        setToFocus(["Movement / Activity"]);
        if (statsListSnapData.sections !== undefined) {
          for (let key in statsListSnapData.sections) {
            if (statsListSnapData.sections[key] < min) {
              min = statsListSnapData.sections[key];
            }
          }
          let focusList = [];
          for (let key in statsListSnapData.sections) {
            if (statsListSnapData.sections[key] === min) {
              for (const element of categories) {
                if (element.id === key) {
                  focusList.push(element.name);
                }
              }
            }
          }
          if (focusList.length === categories.length) {
            setToFocus(["More information needed"]);
          } else {
            while (focusList.length > 3) {
              focusList.pop();
            }
            setToFocus(focusList);
          }
          setIsLoading(false);
        }
      } else {
        setToFocus(["More information needed"]);
        setIsLoading(false);
      }
    }

    async function getNumberOfAssessments(userDocID) {
      const assessmentCollection = collection(
        db,
        "User/" + userDocID + "/Assessments"
      );
      // get number of documents in the collection
      const assessmentDoc = await getDocs(assessmentCollection);

      setNumberOfAssessments(assessmentDoc.size);
    }

    // returns the next level of achievement
    async function getAchievementData(completedConnections) {
      // works not a great fix
      // get the achievement list from the database
      const achievementCollection = collection(db, "acknowledgments");
      // get the steps doc;

      const achievementDoc = await getDoc(doc(achievementCollection, "steps"));
      const achievementList = achievementDoc.data();
      let currentBig = 1000; // there is no achievement after 1000
      // find the next achievement after the number of completed connections
      // loop through the achievement map
      for (const [key] of Object.entries(achievementList)) {
        // if the value is greater than the number of completed connections
        if (Number(key) > completedConnections) {
          // if the value is less than the current big value
          if (Number(key) < currentBig) {
            // set the current big value to the value
            currentBig = key;
          }
        }
      }
      setNextAchievement(currentBig);
    }

    // checks if everything is set up in the user document
  }, []);

  return (
    <motion.div
      className='mt-4'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Transition show={sentInvite} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setSentInvite}
        >
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </TransitionChild>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center lg:pl-60 sm:p-0'>
              <TransitionChild
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <DialogTitle
                        as='h3'
                        className='text-base font-semibold leading-6 text-gray-900'
                      >
                        Invite sent
                      </DialogTitle>
                      <div className='mt-2'>
                        <button
                          type='button'
                          onClick={() => setSentInvite(false)}
                          ref={cancelButtonRef}
                          className='inline-flex col-span-2 w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light'
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition show={showGoal} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setShowGoal}
        >
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </TransitionChild>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <TransitionChild
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div>
                    <div className='mt-3  sm:mt-5'>
                      <DialogTitle
                        as='h3'
                        className='text-lg text-center font-semibold'
                      >
                        Set a new Goal for your Mental Health Connections
                      </DialogTitle>
                      <div className='mt-2'>
                        <Formik
                          initialValues={{}}
                          onSubmit={async (values) => {
                            // update the users goal in the database under about your self
                            const userDoc = doc(db, "User", props.userID);
                            const userSnap = await getDoc(userDoc);
                            if (userSnap.exists()) {
                              let user = userSnap.data();
                              user.about_your_self = values.about_your_self;
                              updateDoc(userDoc, user);
                            }
                          }}
                        >
                          <Form className='w-3/4 mx-auto'>
                            <div className=''>
                              <div className='m-2'>
                                <Field
                                  as='textarea'
                                  rows='5'
                                  name='about_your_self'
                                  id='about_your_self'
                                  className='block w-full shadow-sm sm:text-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md'
                                />
                              </div>
                              <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                                <button
                                  type='submit'
                                  className='inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-light hover:text-dark-text focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2'
                                  onClick={() => setShowGoal(false)}
                                >
                                  Confirm
                                </button>
                                <button
                                  type='button'
                                  className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                                  onClick={() => setShowGoal(false)}
                                  ref={cancelButtonRef}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Banner for the days left in the trial need to check if  */}
      <DaysInTrailBanner />

      <div className='grid grid-cols-1 sm:gap-6 gap-y-4 sm:grid-cols-4 lg:grid-cols-4'>
        {/* upcoming events */}
        <div
          key='why mental health connections'
          className='min-h-card row-span-3 col-span-2 sm:row-span-3 lg:row-span-2 divide-y divide-dark-text border-2 border-dark-text drop-shadow-xl rounded-lg bg-white'
        >
          <div className='flex w-full items-center justify-between space-x-6 p-4'>
            <div className='flex-1 text-clip'>
              <div className='flex items-center space-x-3'>
                <h3 className='text-lg font-medium text-clip text-gray-900'>
                  Why Mental Health Connections?
                </h3>
              </div>
            </div>
          </div>
          <div className='m-2 p-2 font-normal text-base'>
            <p>
              Your Mental Health is not a simple yes / no or singular item, but
              rather it is an interconnected and interdependent concept
              involving multiple areas of health that impact your overall
              wellness & quality of life. Health Circles represent different
              areas that impact your health. Efforts to combine and
              simultaneously invest in multiple Health Circles allows you to
              create beneficial Mental Health Connections.
            </p>
          </div>
        </div>
        <div
          key='your mental health connections goal'
          className=' min-h-card sm:col-start-1  row-span-1 h-full divide-y divide-dark-text border-2 border-dark-text drop-shadow-xl rounded-lg bg-white'
        >
          <div className='flex w-full items-center justify-between space-x-6 p-4'>
            <div className='flex-1 text-clip'>
              <div className='flex items-center space-x-3'>
                <h3 className='text-lg font-medium text-clip text-gray-900'>
                  Your Goal for Mental Health Connections Self Prescription
                </h3>
              </div>
            </div>
          </div>
          <div className='m-2 p-2 font-normal text-base'>
            <p>{userInfo.about_your_self}</p>
          </div>
          <button
            disabled={userInfo.paid === false}
            onClick={() => setShowGoal(true)}
            className='p-2 mr-4 mb-2 float-right rounded-lg bg-primary text-light-text hover:bg-primary-light hover:text-dark-text'
          >
            Set new goal
          </button>
        </div>

        {/* going to the the recommended health circles */}
        <div
          key='recommended goal section'
          className=' min-h-card col-start-1 sm:col-start-2 row-span-1 h-full divide-y divide-dark-text border-2 border-dark-text drop-shadow-xl rounded-lg bg-white'
        >
          <div className='flex w-full items-center justify-between space-x-6 p-4'>
            <div className='flex-1 text-clip'>
              <div className='flex items-center space-x-3'>
                <h3 className='text-lg font-medium text-clip text-gray-900'>
                  Health Circle(s) to Consider Focusing On
                </h3>
              </div>
            </div>
          </div>
          <div className='m-2 p-2 font-semibold text-base'>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              toFocus.map((item) => <p key={item}>{item}</p>)
            )}
          </div>
        </div>

        <div
          key='upcomingEvents'
          className='sm:row-span-3 sm:row-start-2 sm:col-start-3 col-span-2 divide-y divide-dark-text border-2 border-dark-text drop-shadow-xl rounded-lg bg-white'
        >
          <div className='flex w-full items-center justify-between space-x-6 p-4'>
            <div className='flex-1 text-clip'>
              <div className='flex items-center space-x-3'>
                <h3 className='text-lg font-medium text-clip text-gray-900'>
                  Upcoming Mental Health Connections
                </h3>
              </div>
            </div>
          </div>
          <div className='m-2'>
            <div className='-mt-px flex divide-x divide-gray-200 w-full'>
              <div className='flow-root w-full sm:w-3/4 mx-auto min-h-60 overflow-y-auto'>
                <ul className='mt-2'>
                  {events.map((event, eventIdx) => {
                    if (
                      event === "No upcoming events" ||
                      event === "Go to the calendar page to start adding events"
                    ) {
                      return (
                        <li
                          key={event}
                          className='border-2 border-dark-text hover:border-primary rounded-lg p-2 my-2 shadow'
                        >
                          <h1 className='text-lg font-bold text-primary'>
                            {event}
                          </h1>
                        </li>
                      );
                    }
                    if (
                      displayEvents <= eventCount &&
                      event.startTime.seconds >= new Date().getTime() / 1000
                    ) {
                      displayEvents++;
                      return (
                        <li
                          key={event.name}
                          onClick={() => {
                            const newShowEventArray = [...showEventArray];
                            newShowEventArray[eventIdx] =
                              !newShowEventArray[eventIdx];
                            setShowEventArray(newShowEventArray);
                          }}
                          className='border-2 border-dark-text hover:border-primary rounded-lg p-2 my-2 shadow'
                        >
                          <Link key={"calendarLink"}>
                            <div className='relative mb-2'>
                              <div className='relative flex space-x-3'>
                                <div className='flex min-w-0 w-3/4 flex-1 justify-between space-x-4 pt-1.5'>
                                  <div>
                                    <h1 className='text-lg font-bold text-primary'>
                                      {event.name}
                                    </h1>
                                    <p className=' text-sm text-gray-500'>
                                      Start Date :{" "}
                                      {event.startTime
                                        .toDate()
                                        .toLocaleString(undefined, {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                          hour: "numeric",
                                          minute: "numeric",
                                        })}
                                    </p>
                                    {showEventArray[eventIdx] ? (
                                      <div>
                                        <p className=' text-sm text-gray-500'>
                                          End Date :{" "}
                                          {event.endTime
                                            .toDate()
                                            .toLocaleString(undefined, {
                                              year: "numeric",
                                              month: "numeric",
                                              day: "numeric",
                                              hour: "numeric",
                                              minute: "numeric",
                                            })}
                                        </p>
                                        <p className=' text-sm text-gray-500'>
                                          Location : {event.location}
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* weekly Self-Assessment Create a new form every time this is clicked */}
        <div className='sm:col-start-3 col-span-2 row-start-1'>
          {assessmentOpen && userInfo.paid === true ? (
            <motion.div whileHover={{ scale: 1.02, duration: 0.5 }}>
              <Link
                key='weeklySelfAssessment'
                to='/Dashboard'
                id='weeklySelfAssessment'
                state={{ section: "Self Assessment" }}
                onClick={() => makeNewForm()}
                className='min-h-card border-2 border-dark-text drop-shadow-xl rounded-lg bg-white grid grid-cols-3 gap-3 sm:grid-cols-4 hover:border-primary'
              >
                <img
                  alt='Overall_week_icon_green'
                  src={Overall_week_icon_green}
                  className='h-auto w-full sm:w-3/4 m-auto text-primary my-2'
                />

                <div className='col-span-2 sm:col-span-3 divide-y divide-dark-text'>
                  <div className='flex w-full items-center justify-between space-x-6 p-4 '>
                    <div className='flex-1'>
                      <div className='flex items-center space-x-3'>
                        <h2 className='text-lg font-medium text-gray-900'>
                          Weekly Mental Health Self-Assessment
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className='m-2 p-2 font-normal text-base'>
                    <p>Next assessment is available</p>
                  </div>
                </div>
              </Link>
            </motion.div>
          ) : (
            <motion.div
              key='weeklySelfAssessment'
              className='  min-h-card border-2 border-dark-text drop-shadow-xl rounded-lg bg-white grid grid-cols-3 gap-3 sm:grid-cols-4 hover:border-primary'
            >
              <img
                alt='Overall_week_icon_green'
                src={Overall_week_icon_green}
                className='h-auto m-auto w-3/4 sm:w-full xxl:w-3/4 text-primary my-2'
              />

              <div className='col-span-2 sm:col-span-3 divide-y divide-dark-text'>
                <div className='flex w-full items-center justify-between space-x-6 p-4 '>
                  <div className='flex-1'>
                    <div className='flex items-center space-x-3'>
                      <h2 className='text-lg font-medium text-gray-900'>
                        Weekly Mental Health Self-Assessment
                      </h2>
                    </div>
                  </div>
                </div>
                <div className='m-2 p-2 font-normal text-base'>
                  {userInfo.paid === true ? (
                    <p>
                      Next assessment is available in: {assessmentCountDown}{" "}
                      days
                    </p>
                  ) : (
                    <p>Check your payment information</p>
                  )}
                </div>
              </div>
            </motion.div>
          )}
        </div>

        {/* Simple stats card */}
        <div
          key='stats'
          className=' sm:col-span-full divide-2 divide-gray-200 rounded-lg bg-white shadow border-2 border-black '
        >
          <div>
            <div className='-mt-px flex divide-x-2 divide-gray-200'>
              <div className='bg-gray-900 w-full'>
                <div className='mx-auto max-w-full'>
                  <div className='grid grid-cols-1 gap-px bg-white sm:grid-cols-2 lg:grid-cols-4 '>
                    <div
                      key='number of weekly assessments'
                      className='bg-gray-900 px-4 py-4 sm:px-6 lg:px-8'
                    >
                      <p className='text-sm font-medium leading-6 text-gray-400'>
                        Number of Weekly Assessments
                      </p>
                      <p className='mt-2 flex items-baseline gap-x-2'>
                        <span className='text-xl font-semibold tracking-tight text-white'>
                          {numberOfAssessments}
                        </span>
                      </p>
                    </div>
                    <div
                      key='number of completed MH Connections'
                      className='bg-gray-900 px-4 py-4 sm:px-6 lg:px-8'
                    >
                      <p className='text-sm font-medium leading-6 text-gray-400'>
                        Completed MHCs
                      </p>
                      <p className='mt-2 flex items-baseline gap-x-2'>
                        <span className='text-xl font-semibold tracking-tight text-white'>
                          {userInfo.number_completed}
                        </span>
                      </p>
                    </div>
                    <div
                      key='next achievement'
                      className='bg-gray-900 px-4 py-4 p-2 sm:px-6 lg:px-8'
                    >
                      <p className='text-sm font-medium leading-6 text-gray-400'>
                        Next Achievement
                      </p>
                      <p className='mt-2 flex items-baseline gap-x-2'>
                        <span className='text-xl font-semibold tracking-tight text-white'>
                          {nextAchievement}
                        </span>
                      </p>
                    </div>
                    <div
                      key='assessment streak'
                      className='bg-gray-900 px-4 py-4 sm:px-6 lg:px-8'
                    >
                      <p className='text-sm font-medium leading-6 text-gray-400'>
                        Assessment Streak{" "}
                      </p>
                      <p className='mt-2 flex items-baseline gap-x-2'>
                        <span className='text-xl font-semibold tracking-tight text-white'>
                          1
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=' py-16 sm:py-24 lg:py-32 col-span-full'>
          <div className='mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8'>
            <div className='max-w-xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-7'>
              <h2 className='inline sm:block lg:inline xl:block'>
                Want to share the link to this great tool for Mental Health with
                your friends?
              </h2>
            </div>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{ inviteEmail: "" }}
              validate={(values) => {
                const errors = {};

                if (!values.inviteEmail) {
                  errors.inviteEmail = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.inviteEmail
                  )
                ) {
                  errors.inviteEmail = "Invalid email address";
                }

                return errors;
              }}
              onSubmit={(values, { resetForm }) => {
                sendInvite(values);
                setSentInvite(true);
                resetForm();
              }}
            >
              {({
                handleSubmit,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
              }) => (
                <Form className='w-full max-w-md lg:col-span-5 lg:pt-2'>
                  <div className='flex gap-x-4'>
                    <label htmlFor='email-address' className='sr-only'>
                      Email address
                    </label>
                    <Field
                      type='email'
                      name='inviteEmail'
                      id='inviteEmail'
                      className='min-w-0 flex-auto rounded-md border-0 px-3.5 py-2
                        text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                        placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                        focus:ring-primary sm:text-sm sm:leading-6'
                      onChange={(e) => {
                        setFieldValue("inviteEmail", e.target.value);
                        setFieldTouched("inviteEmail", false); // Reset the touched state of the field
                      }}
                    />
                    {touched.inviteEmail && errors.inviteEmail && (
                      <div className='error'>{errors.inviteEmail}</div>
                    )}
                    <button
                      type='submit'
                      className='flex-none rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary'
                    >
                      Send invite
                    </button>
                  </div>
                  <p className='mt-4 text-sm leading-6 text-gray-900'>
                    We care about your data. Read our{" "}
                    <a
                      href='/PrivacyPage'
                      className='font-semibold text-primary hover:underline'
                    >
                      privacy&nbsp;policy.
                    </a>
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
