import React, { Fragment, useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { Dialog, Transition } from "@headlessui/react";
import {
  onAuthStateChanged,
  sendPasswordResetEmail,
  deleteUser,
} from "firebase/auth";
import { db, auth } from "../../firebase-config";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export default function ProfilePage() {
  const [show, setShow] = useState(false);
  const [showGoal, setShowGoal] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [userId, setUserId] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const navigate = useNavigate();
  const [remindersUpdated, setRemindersUpdated] = useState(false);

  const getYear = (date) => {
    let year = date;
    let bYear = new Date(year.seconds * 1000);
    setBirthYear(bYear.getFullYear());
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const collectionRef = collection(db, "User");
        const q = query(collectionRef, where("uuid", "==", user.uid));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const user = [];
          const userId = [];
          // TODO - add error handling
          querySnapshot.forEach((doc) => {
            user.push(doc.data());
            userId.push(doc.id);
          });
          setUserInfo(user[0]);
          setUserId(userId[0]);
          getYear(user[0].year_of_birth);
        });

        return () => unsubscribe();
      }
    });
  }, []);

  useEffect(() => {
    if (userInfo) {
      setShow(true);
    }
  }, [userInfo]);

  const cancelButtonRef = useRef(null);

  const changePassword = () => {
    sendPasswordResetEmail(auth, userInfo.email_username);
    alert("Password reset email sent");
  };

  return show ? (
    <motion.div
      className='mt-4'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* reminders updated popup */}
      <Transition.Root show={remindersUpdated} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setRemindersUpdated}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center lg:pl-60 sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div>
                    <div className='mt-3 text-center sm:mt-5'>
                      <Dialog.Title
                        as='h3'
                        className='text-base font-semibold leading-6 text-gray-900'
                      >
                        Your notifications settings have been updated
                      </Dialog.Title>
                      <div className='mt-2'>
                        <button
                          type='button'
                          onClick={() => setRemindersUpdated(false)}
                          ref={cancelButtonRef}
                          className='inline-flex col-span-2 w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light'
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* password popup */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div>
                    <div className='mt-3  sm:mt-5'>
                      <Dialog.Title as='h1' className=''>
                        <h1 className='text-lg text-center font-bold'>
                          Set new password
                        </h1>
                        <p className='mx-auto'>
                          Enter your new password it must be 8 charters long and
                          have a number and a symbol
                        </p>
                      </Dialog.Title>
                      <div className='mt-2'>
                        <Formik
                          initialValues={{}}
                          onSubmit={(values) => {
                            if (values.password.length < 8) {
                              alert(
                                "Password must be at least 8 characters long"
                              );
                            }
                            if (!values.password.match(/[0-9]/)) {
                              alert("Password must contain a number");
                            }
                            if (!values.password.match(/[!@#$%^&*]/)) {
                              alert("Password must contain a symbol");
                            }
                            if (values.password === values.confirmPassword) {
                              setOpen(false);
                            } else {
                              alert("Passwords do not match");
                            }
                          }}
                        >
                          <Form className='w-3/4 mx-auto'>
                            <div className=''>
                              <label htmlFor='New Password'>
                                <span className='block text-sm font-medium text-gray-700'>
                                  New Password
                                </span>
                              </label>
                              <div className='m-2'>
                                <Field
                                  type='password'
                                  name='password'
                                  id='password'
                                  autoComplete='password'
                                  className='block w-full shadow-sm sm:text-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md'
                                />
                              </div>
                              <label htmlFor='Confirm Password'>
                                <span className='block text-sm font-medium text-gray-700'>
                                  Confirm Password
                                </span>
                              </label>
                              <div className='mt-1'>
                                <Field
                                  type='password'
                                  name='confirmPassword'
                                  id='confirmPassword'
                                  autoComplete='password'
                                  className='block w-full shadow-sm sm:text-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md'
                                />
                              </div>
                              <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                                <button
                                  type='submit'
                                  className='inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-light hover:text-dark-text focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2'
                                  onClick={() => setOpen(false)}
                                >
                                  Confirm
                                </button>
                                <button
                                  type='button'
                                  className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                                  onClick={() => setOpen(false)}
                                  ref={cancelButtonRef}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* edit goal popup */}
      <Transition.Root show={showGoal} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setShowGoal}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div>
                    <div className='mt-3  sm:mt-5'>
                      <Dialog.Title as='h1' className=''>
                        <h1 className='text-lg text-center font-bold'>
                          Set a new Goal for your Mental Health Connections
                        </h1>
                        <p className='mx-auto'></p>
                      </Dialog.Title>
                      <div className='mt-2'>
                        <Formik
                          initialValues={{}}
                          onSubmit={async (values) => {
                            // update the users goal in the database under about your self
                            const userDoc = doc(db, "User", userId);
                            const userSnap = await getDoc(userDoc);
                            if (userSnap.exists()) {
                              let user = userSnap.data();
                              user.about_your_self = values.about_your_self;
                              updateDoc(userDoc, user);
                            }
                          }}
                        >
                          <Form className='w-3/4 mx-auto'>
                            <div className=''>
                              <div className='m-2'>
                                <Field
                                  type='text'
                                  name='about_your_self'
                                  id='about_your_self'
                                  className='block w-full shadow-sm sm:text-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md'
                                />
                              </div>

                              <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                                <button
                                  type='submit'
                                  className='inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-light hover:text-dark-text focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2'
                                  onClick={() => setShowGoal(false)}
                                >
                                  Confirm
                                </button>
                                <button
                                  type='button'
                                  className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                                  onClick={() => setShowGoal(false)}
                                  ref={cancelButtonRef}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* delete account popup */}
      <Transition.Root show={showDeleteAccount} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          initialFocus={cancelButtonRef}
          onClose={setShowDeleteAccount}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                  <div>
                    <div className='mt-3  sm:mt-5'>
                      <Dialog.Title as='h1' className=''>
                        <h1 className='text-lg text-center font-bold'>
                          Delete Account
                        </h1>
                        <p className='mx-auto'>
                          This action will delete your account and all your
                          data. This action is irreversible.
                        </p>
                      </Dialog.Title>
                      <div className='mt-2'>
                        <Formik
                          initialValues={{ delete_account_confirmation: "" }}
                          validate={(values) => {
                            const errors = {};
                            if (
                              values.delete_account_confirmation !==
                              userInfo.email_username
                            ) {
                              errors.delete_account_confirmation =
                                "Please type your email to confirm";
                            }

                            return errors;
                          }}
                          onSubmit={(values) => {
                            // TODO finish the delete function
                            //console.log(values);
                            // delete the user from the database
                            const userDoc = doc(db, "User", userId);
                            // remove credit card information
                            // replace with cloud function call
                            // delete the user from the auth
                            deleteUser(auth.currentUser);
                            deleteDoc(userDoc);
                            navigate("/");
                          }}
                        >
                          {({ errors }) => (
                            <Form className='w-5/6 mx-auto'>
                              <div className=''>
                                <label>
                                  <span className='block text-md  text-gray-700'>
                                    Please type{" "}
                                    <span className='font-bold'>
                                      {userInfo.email_username}
                                    </span>{" "}
                                    to confirm you want to delete your account
                                  </span>
                                </label>
                                <div className='mt-1'>
                                  <Field
                                    type='text'
                                    name='delete_account_confirmation'
                                    id='delete_account_confirmation'
                                    className='block w-full shadow-sm sm:text-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md'
                                  />
                                </div>
                                {errors.delete_account_confirmation ? (
                                  <div className='text-red-500'>
                                    <ErrorMessage name='delete_account_confirmation' />
                                  </div>
                                ) : null}
                                <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                                  <button
                                    type='submit'
                                    className='inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-light hover:text-dark-text focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2'
                                  >
                                    I understand, please delete my account
                                  </button>
                                  <button
                                    type='button'
                                    className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                                    onClick={() => setShowDeleteAccount(false)}
                                    ref={cancelButtonRef}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Formik
        initialValues={{
          product_updates: userInfo.notification.product_updates,
          achievments_and_reminders:
            userInfo.notification.achievments_and_reminders,
          external_resources_and_links:
            userInfo.notification.external_resources_and_links,
          offers: userInfo.notification.offers,
        }}
        onSubmit={async (values) => {
          setRemindersUpdated(true);
          const userDoc = doc(db, "User", userId);
          const userSnap = await getDoc(userDoc);
          if (userSnap.exists()) {
            let user = userSnap.data();
            user.notification = values;
            updateDoc(userDoc, user);
          }
        }}
      >
        <Form className='w-3/4 mx-auto'>
          <div className='space-y-12'>
            {/* Personal information */}
            <div className='grid grid-cols-1 gap-y-5 border-2 rounded-lg border-dark-text pb-12 bg-light-text'>
              <div>
                <h2 className='text-xl font-semibold leading-7 text-dark-text p-4 '>
                  Personal Information
                </h2>
              </div>

              <div className='grid grid-cols-3 gap-y-8 md:col-span-2 px-10'>
                <div className='border-b border-dark-text pb-4'>
                  <label
                    htmlFor='first-name'
                    className='block text-lg font-medium leading-6 '
                  >
                    What encouraged you to participate in the Mental Health
                    Connections Self-Prescription activity?
                  </label>
                </div>
                <div className='col-span-2 border-b border-dark-text pb-4 flex justify-between'>
                  <p className='my-auto'>{userInfo.about_your_self}</p>
                  <button
                    type='button'
                    onClick={() => setShowGoal(true)}
                    className='item-end p-2 rounded-lg mt-2 bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10'
                  >
                    Edit Goal
                  </button>
                </div>
                <div className='border-b border-dark-text pb-4'>
                  <label
                    htmlFor='first-name'
                    className='block text-lg font-medium leading-6 '
                  >
                    Name
                  </label>
                </div>
                <div className='col-span-2 border-b border-dark-text pb-4'>
                  <p>
                    {userInfo.first_name} {userInfo.last_name}
                  </p>
                </div>
                <div className='border-b border-dark-text pb-4'>
                  <label
                    htmlFor='first-name'
                    className='block text-lg font-medium leading-6 '
                  >
                    Birth Year:
                  </label>
                </div>
                <div className='col-span-2 border-b border-dark-text pb-4'>
                  <p>{birthYear}</p>
                </div>
                <div className='border-b border-dark-text pb-4'>
                  <label
                    htmlFor='first-name'
                    className='block text-lg font-medium leading-6 '
                  >
                    Gender
                  </label>
                </div>
                <div className='col-span-2 border-b border-dark-text pb-4'>
                  <p>{userInfo.gender}</p>
                </div>
              </div>
            </div>

            {/* Account information */}
            <div className='grid grid-cols-1 gap-y-10 border-2 rounded-lg border-dark-text bg-light-text pb-12 '>
              <div>
                <h2 className='text-xl font-semibold leading-7  p-4'>
                  Account Information
                </h2>
              </div>

              <div className='grid grid-cols-3  gap-y-8 md:col-span-2 px-10'>
                <div className='border-b border-dark-text pb-4'>
                  <label
                    htmlFor='first-name'
                    className='block text-lg font-medium leading-6 '
                  >
                    Username/Email
                  </label>
                </div>
                <div className='col-span-2 border-b border-dark-text pb-4'>
                  {userInfo.email_username ? (
                    <p>{userInfo.email_username}</p>
                  ) : (
                    <p>{userInfo.email}</p>
                  )}
                </div>

                <div className='border-b border-dark-text pb-4'>
                  <label
                    htmlFor='last-name'
                    className='block text-lg font-medium leading-6 '
                  >
                    Password
                  </label>
                </div>
                <div className='col-span-2 flex justify-between gap-x-6 border-b border-dark-text pb-2'>
                  <div className='flex min-w-0 gap-x-4'>
                    <p>********</p>
                  </div>
                  <div className=' shrink-0 sm:flex sm:flex-col sm:items-end'>
                    <button
                      type='button'
                      onClick={() => changePassword()}
                      className=' text-md leading-6 text-light-text p-2 rounded bg-primary hover:bg-primary-light hover:text-dark-text'
                    >
                      Change Password
                    </button>
                  </div>
                </div>
                <div className='border-b border-dark-text pb-4'>
                  <label
                    htmlFor='Delete Account'
                    className='block text-lg font-medium leading-6'
                  >
                    Edit Account
                  </label>
                </div>
                <div className='col-span-2 flex justify-between gap-x-6 border-b border-dark-text pb-2'>
                  <div className='flex min-w-0 gap-x-4'>
                    <p>
                      You can edit your account and update your subscription
                      information here.
                    </p>
                  </div>
                  <div className=' shrink-0 sm:flex sm:flex-col sm:items-end'>
                    <button
                      type='button'
                      onClick={() =>
                        (window.location.href =
                          "https://billing.stripe.com/p/login/test_4gw6pncOcblKb0A144")
                      }
                      // onClick={() => auth.delete()}
                      className=' text-md leading-6 text-light-text p-2 rounded bg-primary hover:bg-primary-light hover:text-dark-text'
                    >
                      Edit Account
                    </button>
                  </div>
                </div>
                {/* <div>
                  <label
                    htmlFor='Delete Account'
                    className='block text-lg font-medium leading-6'
                  >
                    Delete Account
                  </label>
                </div>
                <div className='col-span-2 flex justify-between gap-x-6 pb-2'>
                  <div className='flex min-w-0 gap-x-4'>
                    <p>
                      Once you delete your account, there is no going back.
                      Please be certain.
                    </p>
                  </div>
                  <div className=' shrink-0 sm:flex sm:flex-col sm:items-end'>
                    <button
                      type='button'
                      // onClick={() => auth.delete()}
                      onClick={() => setShowDeleteAccount(true)}
                      className=' text-md leading-6 text-light-text p-2 rounded bg-primary hover:bg-primary-light hover:text-dark-text'
                    >
                      Delete Account
                    </button>
                  </div>
                </div> */}
              </div>
            </div>

            <div className='grid grid-cols-1 gap-x-8 gap-y-10 border-2 rounded-lg border-dark-text bg-light-text p-4 pb-12 md:grid-cols-3'>
              <div>
                <h2 className='text-lg font-semibold '>Notifications</h2>
                <p className='mt-1 text-sm leading-6 text-gray-600'>
                  We'll always let you know about important changes, but you
                  pick what else you want to hear about. By signing up, you
                  agree to Mental Health Connections Privacy Policy. Your
                  personal details are strictly for our use, and you can
                  unsubscribe at any time.
                </p>
              </div>

              <div className='max-w-2xl space-y-10 md:col-span-2'>
                <fieldset>
                  <div className='mt-6 space-y-6'>
                    <div className='relative flex gap-x-3'>
                      <div className='flex h-6 items-center'>
                        <Field
                          type='checkbox'
                          id='product_updates'
                          name='product_updates'
                          className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary'
                        />
                      </div>
                      <div className='text-sm leading-6'>
                        <label htmlFor='comments' className='font-medium '>
                          Product Updates
                        </label>
                        <p className='text-gray-500'>
                          Receive notifications when we release new features or
                          updates.
                        </p>
                      </div>
                    </div>
                    <div className='relative flex gap-x-3'>
                      <div className='flex h-6 items-center'>
                        <Field
                          id='achievments_and_reminders'
                          name='achievments_and_reminders'
                          type='checkbox'
                          className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary'
                        />
                      </div>
                      <div className='text-sm leading-6'>
                        <label htmlFor='candidates' className='font-medium '>
                          Receive notifications of reminders and achievement
                          notices
                        </label>
                        {/* <p className='text-gray-500'>
                                Say up to date with the latest research.
                              </p> */}
                      </div>
                    </div>
                    <div className='relative flex gap-x-3'>
                      <div className='flex h-6 items-center'>
                        <Field
                          id='external_resources_and_links'
                          name='external_resources_and_links'
                          type='checkbox'
                          className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary'
                        />
                      </div>
                      <div className='text-sm leading-6'>
                        <label htmlFor='offers' className='font-medium '>
                          Updates to External Resources & Links
                        </label>
                        <p className='text-gray-500'>
                          Receive notifications when a new resource or link is
                          added.
                        </p>
                      </div>
                    </div>
                    <div className='relative flex gap-x-3'>
                      <div className='flex h-6 items-center'>
                        <Field
                          id='offers'
                          name='offers'
                          type='checkbox'
                          className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary'
                        />
                      </div>
                      <div className='text-sm leading-6'>
                        <label htmlFor='offers' className='font-medium '>
                          Offers
                        </label>
                        <p className='text-gray-500'>
                          Receive notifications when we are having a promotion.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-6 flex items-center justify-end gap-x-6'>
                    <button
                      type='submit'
                      onClick={() => setRemindersUpdated(true)}
                      className='rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:text-dark-text hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary'
                    >
                      Update
                    </button>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </motion.div>
  ) : null;
}
