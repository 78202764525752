import React, { useState, useEffect } from "react";
import axios from "axios";
import { CalendarIcon } from "@heroicons/react/24/outline";

const ServerConnectionTest = (props) => {
  const [calendarUrl, setCalendarUrl] = useState(null);

  useEffect(() => {
    setCalendarUrl(props.calendarLink);
  }, [props.calendarLink]);

  return (
    <div>
      {calendarUrl === null ? (
        <p>Connecting...</p>
      ) : (
        <a
          className='p-2 mb-2 rounded-lg bg-primary text-light-text hover:bg-primary-light font-semibold hover:text-dark-text max-h-10 flex align-middle'
          href={calendarUrl}
        >
          <CalendarIcon className='h-6 w-6 ' />
          <span className='ml-2'>Subscribe on this Device</span>
        </a>
      )}
      <p>
        Clicking the button above will open your default calendar application
        and display instructions for adding the subscription.
      </p>
    </div>
  );
};

export default ServerConnectionTest;
