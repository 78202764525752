import React, { useEffect } from "react";
import { Field } from "formik";
import { use } from "react";
import { fi } from "date-fns/locale";

const generateHourOptions = () => {
  const options = [];
  for (let hour = 1; hour <= 12; hour++) {
    const value = String(hour).padStart(2, "0");
    options.push(
      <option key={value} value={value}>
        {value}
      </option>
    );
  }
  return options;
};

const generateMinuteOptions = () => {
  const options = [];
  for (let minute = 0; minute < 60; minute += 5) {
    const value = String(minute).padStart(2, "0");
    options.push(
      <option key={value} value={value}>
        {value}
      </option>
    );
  }
  return options;
};

const amPmOptions = () => {
  return (
    <>
      <option value='AM'>AM</option>
      <option value='PM'>PM</option>
    </>
  );
};

// takes a time string in the format "HH:MM" using the 24-hour clock
// returns 3 select fields for hour, minute, and AM/PM
export default function CustomTimeJump({ field, form, ...props }) {
  useEffect(() => {
    // convert the single time string to an array of [hour, minute, AM/PM]
    const timeArray = field.value.split(":");
    let hour = timeArray[0];
    const minute = timeArray[1];
    let amPm = "AM";
    if (hour >= 12) {
      amPm = "PM";
      hour -= 12;
    }

    form.setFieldValue(`${field.name}Hour`, hour.toString().padStart(2, "0"));
    form.setFieldValue(`${field.name}Minute`, minute);
    form.setFieldValue(`${field.name}AmPm`, amPm);
  }, [field.value]);

  useEffect(() => {
    // check if form values is filled out
    if (!form.values[`${field.name}Hour`]) return;

    if (parseInt(form.values[`${field.name}Hour`], 10) > 12) {
      let tmpHours = parseInt(form.values[`${field.name}Hour`], 10);
      console.log("tmpHours", tmpHours);
      tmpHours -= 12;
      form.setFieldValue(
        `${field.name}Hour`,
        tmpHours.toString().padStart(2, "0")
      );
    }
  }, [form.values[`${field.name}Hour`]]);

  return (
    <div className='flex space-x-2 justify-center'>
      <Field
        as='select'
        name={`${field.name}Hour`}
        className='mt-1 focus:ring-primary focus:border-primary shadow-sm
        sm:text-sm border-gray-300 rounded-md'
      >
        {generateHourOptions()}
      </Field>
      <div className='my-auto'>:</div>

      <Field
        as='select'
        name={`${field.name}Minute`}
        className='mt-1 focus:ring-primary focus:border-primary shadow-sm sm:text-sm border-gray-300 rounded-md'
      >
        {generateMinuteOptions()}
      </Field>
      <Field
        as='select'
        name={`${field.name}AmPm`}
        className='mt-1 focus:ring-primary focus:border-primary shadow-sm sm:text-sm border-gray-300 rounded-md'
      >
        {amPmOptions()}
      </Field>
    </div>
  );
}
