import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Dialog,
  Transition,
  Switch,
  Combobox,
  DialogPanel,
  TransitionChild,
  DialogTitle,
} from "@headlessui/react";
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  getDocs,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { Formik, Form, Field } from "formik";
import { db, auth } from "../../../firebase-config";
import CreateEvent from "../../../Components/Event/CreateEvent";
import CompleteEvent from "../../../Components/Event/CompleteEvent";
import { CalendarIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import createEventButton from "../../../Images/CreateEventButton.svg";
import CancelEvent from "../../../Components/Event/CancelEvent";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import MostFrequentConnection from "../../../Components/Connection/MostFrequentConnection";
import NonagoneMap from "../../Dashboard/Components/NonagoneMap/NonagoneMap";
import ReactPlayer from "react-player";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Calendar(props) {
  Calendar.propTypes = {
    userID: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
  };

  const [searchConnections, setSearchConnections] = useState("");
  const navigate = useNavigate();
  const [showEvents, setShowEvents] = useState(false);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [docID, setDocID] = useState("");
  const [calendarID, setCalendarID] = useState("");
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [calendarReminders, setCalendarReminders] = useState([]);
  const [currentConnection, setCurrentConnection] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [repeatEnabled, setRepeatEnabled] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [personList, setPersonList] = useState([]);
  const [weekSelected, setWeekSelected] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [repeatType, setRepeatType] = useState("day");
  const [openReminder, setOpenReminder] = useState(false);
  let connectionsDisplayed = 0;
  const [attendeesQuery, setAttendeesQuery] = useState("");
  const [attendeesList, setAttendeesList] = useState([]);
  const [mostFrequent, setMostFrequent] = useState([]);
  const [payment, setPayment] = useState(false);
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const filteredPeople =
    attendeesQuery === ""
      ? []
      : personList.filter((person) => {
          return person.email
            .toLowerCase()
            .includes(attendeesQuery.toLowerCase());
        });

  useEffect(() => {
    // set today's date to be true
    const today = new Date();
    // get number of the day of the week
    const day = today.getDay();
    // update weekSelected to have the current day of the week selected
    const updatedWeekSelected = [...weekSelected];
    updatedWeekSelected[day - 1] = true;
    setWeekSelected(updatedWeekSelected);
  }, []);

  // person is the object needs to handle the object and the email or i can fix it to do just he object
  const addPerson = (person) => {
    // Check if the person already exists in the list based on their email
    const personExists = attendeesList.some(
      (attendee) => attendee.email === person.email
    );

    if (!personExists) {
      // If the person does not exist, add them to the list
      setAttendeesList([...attendeesList, person]);
    }

    setAttendeesQuery("");

    //check if the person is already in personList
    //if not add them
    let found = 0; // 0 is false
    const personDoc = doc(db, "User/" + props.userID + "/Person", "Person");
    for (const tmp of personList) {
      if (tmp.email === person.email) {
        found = 1;
        return;
      }
    }
    if (found === 0) {
      updateDoc(personDoc, {
        personList: [...personList, person],
      });
    }
  };

  //person is the email not the object
  const removePerson = (person) => {
    const newArray = attendeesList.filter((item) => item.email !== person);
    setAttendeesList(newArray);
  };

  const [sortedEvents, setSortedEvents] = useState([]);

  // update the sorted events
  const updatedEvents = (mostFrequent, calendarEvents) => {
    // list is already sorted by mostFrequent
    const sortedEvents = mostFrequent.flatMap((item) => {
      return calendarEvents.filter(
        (event) => event.name === item && event.scheduled === false
      );
    });
    setSortedEvents(sortedEvents.slice(0, 6));
  };

  // start is the day the event starts
  const completeEvent = async (event) => {
    setOpenPopup(true);
    setLoading(true);
    //

    const updatedEventList = await CompleteEvent(docID, event.name);
    updatedEvents(await MostFrequentConnection(docID), updatedEventList);
    setCalendarEvents(updatedEventList);
  };

  const cancelEvent = async (event) => {
    setLoading(true);
    // Cancel the event and update the state
    const updatedEvents = await CancelEvent(docID, event.name);
    setCalendarEvents(updatedEvents);
  };

  const saveForm = async () => {
    // set the completed map to yes
    const completed = props.information.completed;
    completed.Overall_Mental_Health = "yes";
    completed.Add_connections_to_your_calendar = "yes";
    completed.Create_update_Connections = "yes";
    completed.Populate_your_Health_Circles = "yes";
    // update the assessment
    const updatedDoc = doc(
      db,
      "User/" + props.userID + "/Assessments",
      props.docInfo
    );
    // get the updated doc
    const docSnap = await getDoc(updatedDoc);

    if (!docSnap.exists()) {
      console.error("there was an error saving the weekly assessment form.");
    } else {
      // update the data
      const docData = docSnap.data();
      const updateAssessment = {
        ...docData,
        completed: completed,
      };
      // update the assessment
      updateDoc(updatedDoc, updateAssessment);
      navigate("/Dashboard", {
        state: { section: "dashboard", formCompleted: false },
      });
    }
  };

  const translateTimes = (value) => {
    let date = value.startTime.seconds * 1000;
    let date2 = value.endTime.seconds * 1000;

    //get year, month, day, hour, minute
    let startDate = new Date(date);
    let endDate = new Date(date2);

    // get the date in the correct format
    let startYear = startDate.getFullYear();
    let startMonth = String(startDate.getMonth() + 1).padStart(2, "0");
    let startDay = String(startDate.getDate()).padStart(2, "0");
    let endYear = endDate.getFullYear();
    let endMonth = String(endDate.getMonth() + 1).padStart(2, "0");
    let endDay = String(endDate.getDate()).padStart(2, "0");

    // get the time in the correct format
    let startHour = startDate.getHours();
    let startMinute = startDate.getMinutes();
    let endHour = endDate.getHours();
    let endMinute = endDate.getMinutes();

    // output yyyy-mm-dd
    let startDateString = startYear + "-" + startMonth + "-" + startDay;
    let endDateString = endYear + "-" + endMonth + "-" + endDay;
    // output hh:mm (24 hour time)
    let startTimeString =
      String(startHour).padStart(2, "0") +
      ":" +
      String(startMinute).padStart(2, "0") +
      ":00";
    let endTimeString =
      String(endHour).padStart(2, "0") +
      ":" +
      String(endMinute).padStart(2, "0") +
      ":00";
    setStartDate(startDateString);
    setEndDate(endDateString);
    setStartTime(startTimeString);
    setEndTime(endTimeString);
  };
  const translateNewDate = (value) => {
    // convert the date to the correct format yyy-mm-dd
    let date = value;
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let day = String(date.getDate()).padStart(2, "0");
    let dateString = year + "-" + month + "-" + day;
    return dateString;
  };

  const translateNewTime = (value) => {
    // output hh:mm (24 hour time)
    let date = value;
    let hour = date.getHours();
    let minute = date.getMinutes();
    let timeString =
      String(hour).padStart(2, "0") +
      ":" +
      String(minute).padStart(2, "0") +
      ":00";
    return timeString;
  };
  const [nextAchievement, setNextAchievement] = useState(0);
  // returns the next level of achievement
  const getAchievementData = async (completedConnections) => {
    // get the achievement list from the database
    const achievementCollection = collection(db, "acknowledgments");
    const achievementDoc = await getDoc(doc(achievementCollection, "steps"));
    const achievementList = achievementDoc.data();
    let currentBig = 1000; // there is no achievement after 1000
    // find the next achievement after the number of completed connections
    // loop through the achievement map
    for (const [key] of Object.entries(achievementList)) {
      // if the value is greater than the number of completed connections
      if (Number(key) > completedConnections) {
        // if the value is less than the current big value
        if (Number(key) < currentBig) {
          // set the current big value to the value
          currentBig = key;
        }
      }
    }
    if (currentBig === 1000) {
      setNextAchievement(1000);
    }
    setNextAchievement(currentBig);
  };

  //sets the current connection to the item begin selected and then opens the popup so the user can create an event
  const selectConnection = (value) => {
    // if there is a start time and end time convert them to the correct format
    // else keep them as 0 and they will be handled by the initial values in the form
    if (value.startTime.seconds !== 0 && value.endTime.seconds !== 0) {
      translateTimes(value);
    } else {
      setStartDate(false);
      setEndDate(false);
      setStartTime(false);
      setEndTime(false);
    }

    if (value.repeatEnd !== "none") {
      setRepeatEnabled(true);
      setRepeatType(value.repeatType);
    }
    if (value.allDay === true) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
    if (value.repeatFlag === true) {
      setRepeatEnabled(true);
      setRepeatType(value.repeatType);
    } else {
      setRepeatEnabled(false);
    }
    setAttendeesList(value.attendeesList);

    setCurrentConnection(value);

    setOpen(true);
  };

  const [numberOfAssessments, setNumberOfAssessments] = useState(0);
  const getNumberOfAssessments = async (userDocID) => {
    const assessmentCollection = collection(
      db,
      "User/" + userDocID + "/Assessments"
    );
    // get number of documents in the collection
    const assessmentDoc = await getDocs(assessmentCollection);

    setNumberOfAssessments(assessmentDoc.size);
  };
  const cancelButtonRef = useRef(null);

  const getPersonList = async (userDocID) => {
    const personCollection = collection(db, "User/" + userDocID + "/Person");
    const personDoc = await getDocs(personCollection);

    const personList = [];
    personDoc.forEach((doc) => {
      personList.push(doc.data());
    });
    setPersonList(personList[0].personList);
  };

  const resetCreateEvent = () => {
    setRepeatEnabled(false);
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchAndUpdateEvents = async () => {
      if (calendarEvents !== undefined && calendarEvents.length > 0) {
        const mostFrequent = await MostFrequentConnection(docID);
        updatedEvents(mostFrequent, calendarEvents);
        setLoading(false);
      }
    };

    fetchAndUpdateEvents();
  }, [calendarEvents]);
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const collectionRef = collection(db, "User");
        const q = query(collectionRef, where("uuid", "==", user.uid));
        let userDocID = "";

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const user = [];
          // TODO - add error handling
          querySnapshot.forEach((doc) => {
            user.push(doc.data());
            userDocID = doc.id;
            setDocID(doc.id);
            setPayment(doc.data().paid);
          });
          setUserInfo(user[0]);
          getPersonList(userDocID);
          getNumberOfAssessments(userDocID);
          getAchievementData(user[0].number_completed);
          setMostFrequent(await MostFrequentConnection(userDocID));
          // there should only be one document need error checking

          const calendarDoc = doc(
            db,
            "User/" + userDocID + "/Calendar",
            "Calendar"
          );

          const calendarSnap = await getDoc(calendarDoc);
          setCalendarID(calendarSnap.id);
          setCalendarEvents(calendarSnap.data().events);
          setCalendarReminders(calendarSnap.data().reminders);
          updatedEvents(
            await MostFrequentConnection(userDocID),
            calendarSnap.data().events
          );

          setShowEvents(true);

          // there should only be one document need error checking
        });

        return () => unsubscribe();

        // get the connections and the Calendar events
      }
    });
  }, []);

  return showEvents ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {props.page === "FormDashboard" ? (
        <div className='flex justify-between'>
          <Link
            to='/dashboard'
            state={{
              section: "Self Assessment",
              formSection: "Create Connections",
            }}
            className='bg-primary  text-light-text font-semibold rounded-lg px-4 py-2 m-4 hover:bg-primary-light hover:text-dark-text max-h-10 min-w-button text-center'
          >
            Back
          </Link>

          <Link
            onClick={() => saveForm()}
            className='bg-primary  text-light-text font-semibold rounded-lg px-4 py-2 m-4 hover:bg-primary-light hover:text-dark-text max-h-10 min-w-button text-center'
          >
            Complete Assessment
          </Link>
        </div>
      ) : (
        <div className='flex justify-between'>
          <Link
            to='/dashboard'
            state={{
              section: "Create MH Connections",
            }}
            className='bg-primary text-light-text font-semibold rounded-lg px-4 py-2 m-4 hover:bg-primary-light hover:text-dark-text max-h-10 min-w-button text-center'
          >
            Back
          </Link>
        </div>
      )}

      <div className='grid grid-cols-1 gap-4 xxxl:grid-cols-6 '>
        {/* Popup for completing an event */}
        <Transition show={openPopup} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-10'
            initialFocus={cancelButtonRef}
            onClose={setOpenPopup}
          >
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </TransitionChild>

            <div className='fixed inset-0 z-10 overflow-y-auto'>
              <div className='flex min-h-full justify-center p-4 text-center items-center lg:pl-60 sm:p-0'>
                <TransitionChild
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                  <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                    <div>
                      <div className='mt-3 text-center sm:mt-5'>
                        <DialogTitle
                          as='h3'
                          className='text-base font-semibold leading-6 text-gray-900'
                        >
                          Congratulations on completing your MHC
                        </DialogTitle>
                        <div className='mt-2'>
                          <button
                            type='button'
                            onClick={() => setOpenPopup(false)}
                            ref={cancelButtonRef}
                            className='inline-flex col-span-2 w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light'
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>

        <Transition show={openReminder} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-10'
            initialFocus={cancelButtonRef}
            onClose={setOpenReminder}
          >
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </TransitionChild>

            <div className='fixed inset-0 z-10 overflow-y-auto'>
              <div className='flex min-h-full justify-center p-4 text-center items-center lg:pl-60 sm:p-0'>
                <TransitionChild
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                  <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                    <div>
                      <div className='mt-3 text-center sm:mt-5'>
                        <DialogTitle
                          as='h3'
                          className='text-base font-semibold leading-6 text-gray-900'
                        >
                          Reminder settings updated
                        </DialogTitle>
                        <div className='mt-2'>
                          <button
                            type='button'
                            onClick={() => setOpenReminder(false)}
                            ref={cancelButtonRef}
                            className='inline-flex col-span-2 w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light'
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>

        <Transition show={open} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-10'
            initialFocus={cancelButtonRef}
            onClose={resetCreateEvent}
          >
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </TransitionChild>

            <div className='fixed inset-0 z-10 overflow-y-auto'>
              <div className='flex min-h-full py-20 sm:py-2 lg:pl-72 items-end justify-center p-4 text-center sm:items-center'>
                <TransitionChild
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                  <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-2xl sm:p-6'>
                    <div>
                      <div className='mt-3 text-center sm:mt-5'>
                        <DialogTitle
                          as='h3'
                          className='text-base font-semibold leading-6 text-gray-900'
                        >
                          Create event for {currentConnection.name}
                        </DialogTitle>
                        <div className='mt-2'>
                          <Formik
                            initialValues={{
                              attendees: "",
                              startDate: startDate
                                ? startDate
                                : translateNewDate(new Date()),
                              endDate: endDate
                                ? endDate
                                : translateNewDate(new Date()),
                              startTime: startTime
                                ? startTime
                                : translateNewTime(new Date()),
                              endTime: endTime
                                ? endTime
                                : translateNewTime(new Date()),
                              repeatFlag: currentConnection.repeatFlag || false,
                              repeatType: currentConnection.repeatType || "day",
                              repeatEnd:
                                currentConnection.repeatEnd !== "none"
                                  ? translateNewDate(
                                      new Date(currentConnection.repeatEnd)
                                    )
                                  : translateNewDate(new Date()),
                              location: currentConnection.location || "",
                            }}
                            validate={async (values) => {
                              const errors = {};

                              if (values.endDate < values.startDate) {
                                values.endDate = values.startDate;
                              }
                              if (values.endTime < values.startTime) {
                                values.endTime = values.startTime;
                              }

                              return errors;
                            }}
                            onSubmit={async (values) => {
                              setCalendarEvents(
                                await CreateEvent(
                                  currentConnection.name,
                                  values.location,
                                  values.startDate,
                                  values.endDate,
                                  values.startTime,
                                  values.endTime,
                                  props.userID,
                                  enabled,
                                  currentConnection.sectionList,
                                  repeatType,
                                  values.repeatEnd,
                                  attendeesList,
                                  values.repeatFlag,
                                  weekSelected
                                )
                              );

                              setOpen(false);
                            }}
                          >
                            {({ setFieldValue }) => (
                              <Form className=''>
                                <div className='mt-4 grid grid-cols-1 sm:grid-cols-3 '>
                                  <div className='col-span-3 mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3'>
                                    <div>
                                      <label
                                        htmlFor='date'
                                        className='block text-sm font-medium text-gray-700'
                                      >
                                        Start Date
                                      </label>
                                      <Field
                                        type='date'
                                        name='startDate'
                                        id='startDate'
                                        autoComplete='date'
                                        className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                      />
                                    </div>
                                    <div>
                                      <label
                                        htmlFor='date'
                                        className='block text-sm font-medium text-gray-700'
                                      >
                                        End Date
                                      </label>
                                      <Field
                                        type='date'
                                        name='endDate'
                                        id='endDate'
                                        autoComplete='date'
                                        className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                      />
                                    </div>
                                    <div className='p-4 py-6 flex flex-row align-middle sm:flex-col'>
                                      <div className='flex items-center'>
                                        <Switch
                                          checked={enabled}
                                          name='allDay'
                                          id='allDay'
                                          onChange={setEnabled}
                                          className={classNames(
                                            enabled
                                              ? "bg-primary"
                                              : "bg-gray-200",
                                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                          )}
                                        >
                                          <span
                                            aria-hidden='true'
                                            name='allDay'
                                            id='allDay'
                                            className={classNames(
                                              enabled
                                                ? "translate-x-5"
                                                : "translate-x-0",
                                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                            )}
                                          />
                                        </Switch>
                                        <label
                                          as='span'
                                          name='allDay'
                                          id='allDay'
                                          className='ml-3 text-sm'
                                        >
                                          <span className='font-medium text-gray-900'>
                                            All day
                                          </span>
                                        </label>
                                      </div>
                                      <div
                                        as='div'
                                        className='flex items-center ml-2 sm:ml-0 sm:mt-4'
                                      >
                                        <Switch
                                          checked={repeatEnabled}
                                          name='repeatFlag'
                                          id='repeatFlag'
                                          onChange={(newValue) => {
                                            setFieldValue(
                                              "repeatFlag",
                                              newValue
                                            );
                                            setRepeatEnabled(!repeatEnabled);
                                          }}
                                          className={classNames(
                                            repeatEnabled
                                              ? "bg-primary"
                                              : "bg-gray-200",
                                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                          )}
                                        >
                                          <span
                                            aria-hidden='true'
                                            className={classNames(
                                              repeatEnabled
                                                ? "translate-x-5"
                                                : "translate-x-0",
                                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                            )}
                                          />
                                        </Switch>
                                        <label
                                          as='span'
                                          className='ml-3 text-sm'
                                        >
                                          <span className='font-medium text-gray-900'>
                                            Repeat
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='row-start-1 sm:row-start-2 sm:row-span-2 sm:col-start-3'>
                                    {currentConnection.sectionList ===
                                      undefined ||
                                    currentConnection.sectionList.length ===
                                      0 ? null : (
                                      <NonagoneMap
                                        data={currentConnection.sectionList}
                                      />
                                    )}
                                  </div>

                                  {enabled === false ? (
                                    <div className='col-span-3 sm:col-span-2 mt-4 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                                      <div>
                                        <label
                                          htmlFor='time'
                                          className='block text-sm font-medium text-gray-700'
                                        >
                                          Start time
                                        </label>
                                        <Field
                                          type='time'
                                          name='startTime'
                                          id='startTime'
                                          autoComplete='time'
                                          className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                        />
                                      </div>
                                      <div>
                                        <label
                                          htmlFor='time'
                                          className='block text-sm font-medium text-gray-700'
                                        >
                                          End time
                                        </label>
                                        <Field
                                          type='time'
                                          name='endTime'
                                          id='endTime'
                                          autoComplete='time'
                                          className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='col-span-3 sm:col-span-2 mt-4'>
                                      <label
                                        htmlFor='location'
                                        className='block text-sm font-medium text-gray-700'
                                      >
                                        Location
                                      </label>
                                      <Field
                                        type='text'
                                        name='location'
                                        id='location'
                                        autoComplete='location'
                                        className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                      />
                                    </div>
                                  )}
                                  {enabled === false ? (
                                    <div className='col-span-3 sm:col-span-2 mt-4'>
                                      <label
                                        htmlFor='location'
                                        className='block text-sm font-medium text-gray-700'
                                      >
                                        Location
                                      </label>

                                      <Field
                                        type='text'
                                        name='location'
                                        id='location'
                                        autoComplete='location'
                                        className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                      />
                                    </div>
                                  ) : null}

                                  {/* <NonagoneMap
                                    className='row-start-2 row-end-3'
                                    data={currentConnection.sectionList}
                                  /> */}

                                  {repeatEnabled === true ? (
                                    <motion.div className='col-span-2 mt-4'>
                                      <label
                                        htmlFor='repeat'
                                        className='block text-sm font-medium text-gray-700'
                                      >
                                        Repeat
                                      </label>
                                      <p>
                                        Repeat every
                                        <Field
                                          as='select'
                                          name='repeatType'
                                          id='repeatType'
                                          onChange={(e) => {
                                            setFieldValue(
                                              "repeatType",
                                              e.target.value
                                            );
                                            setRepeatType(e.target.value);
                                          }}
                                          className='ml-2 mt-1 focus:ring-primary focus:border-primary  shadow-sm sm:text-sm border-gray-300 rounded-md'
                                        >
                                          <option value='day'>day</option>
                                          <option value='week'>week</option>
                                        </Field>
                                      </p>
                                      {repeatType === "week" ? (
                                        <ul
                                          key='dayList'
                                          className='flex flex-row justify-between mt-4'
                                        >
                                          <motion.li
                                            animate={{
                                              scale: weekSelected[0] ? 1.2 : 1,
                                            }}
                                            key='Monday'
                                            onClick={() => {
                                              const updatedWeekSelected = [
                                                ...weekSelected,
                                              ]; // Create a copy of the array
                                              updatedWeekSelected[0] =
                                                !updatedWeekSelected[0]; // Toggle the value of the first element
                                              setWeekSelected(
                                                updatedWeekSelected
                                              ); // Update the state with the new array
                                            }}
                                            className={classNames(
                                              weekSelected[0]
                                                ? "bg-primary-light text-dark-text"
                                                : "bg-primary rounded-full text-light-text",
                                              "w-2/12 cursor-pointer mx-2"
                                            )}
                                          >
                                            M
                                          </motion.li>
                                          <motion.li
                                            animate={{
                                              scale: weekSelected[1] ? 1.2 : 1,
                                            }}
                                            key='Tuesday'
                                            onClick={() => {
                                              const updatedWeekSelected = [
                                                ...weekSelected,
                                              ]; // Create a copy of the array
                                              updatedWeekSelected[1] =
                                                !updatedWeekSelected[1]; // Toggle the value of the first element
                                              setWeekSelected(
                                                updatedWeekSelected
                                              ); // Update the state with the new array
                                            }}
                                            className={classNames(
                                              weekSelected[1]
                                                ? "bg-primary-light text-dark-text"
                                                : "bg-primary rounded-full text-light-text",
                                              "w-2/12 cursor-pointer mx-2"
                                            )}
                                          >
                                            T
                                          </motion.li>
                                          <motion.li
                                            animate={{
                                              scale: weekSelected[2] ? 1.2 : 1,
                                            }}
                                            key='Wednesday'
                                            onClick={() => {
                                              const updatedWeekSelected = [
                                                ...weekSelected,
                                              ]; // Create a copy of the array
                                              updatedWeekSelected[2] =
                                                !updatedWeekSelected[2]; // Toggle the value of the first element
                                              setWeekSelected(
                                                updatedWeekSelected
                                              ); // Update the state with the new array
                                            }}
                                            className={classNames(
                                              weekSelected[2]
                                                ? "bg-primary-light text-dark-text"
                                                : "bg-primary rounded-full text-light-text",
                                              "w-2/12 cursor-pointer mx-2"
                                            )}
                                          >
                                            W
                                          </motion.li>
                                          <motion.li
                                            animate={{
                                              scale: weekSelected[3] ? 1.2 : 1,
                                            }}
                                            key='Thursday'
                                            onClick={() => {
                                              const updatedWeekSelected = [
                                                ...weekSelected,
                                              ]; // Create a copy of the array
                                              updatedWeekSelected[3] =
                                                !updatedWeekSelected[3]; // Toggle the value of the first element
                                              setWeekSelected(
                                                updatedWeekSelected
                                              ); // Update the state with the new array
                                            }}
                                            className={classNames(
                                              weekSelected[3]
                                                ? "bg-primary-light text-dark-text"
                                                : "bg-primary rounded-full text-light-text",
                                              "w-2/12 cursor-pointer mx-2"
                                            )}
                                          >
                                            T
                                          </motion.li>
                                          <motion.li
                                            animate={{
                                              scale: weekSelected[4] ? 1.2 : 1,
                                            }}
                                            key='Friday'
                                            onClick={() => {
                                              const updatedWeekSelected = [
                                                ...weekSelected,
                                              ]; // Create a copy of the array
                                              updatedWeekSelected[4] =
                                                !updatedWeekSelected[4]; // Toggle the value of the first element
                                              setWeekSelected(
                                                updatedWeekSelected
                                              ); // Update the state with the new array
                                            }}
                                            className={classNames(
                                              weekSelected[4]
                                                ? "bg-primary-light text-dark-text"
                                                : "bg-primary rounded-full text-light-text",
                                              "w-2/12 cursor-pointer mx-2"
                                            )}
                                          >
                                            F
                                          </motion.li>
                                          <motion.li
                                            animate={{
                                              scale: weekSelected[5] ? 1.2 : 1,
                                            }}
                                            key='Saturday'
                                            onClick={() => {
                                              const updatedWeekSelected = [
                                                ...weekSelected,
                                              ]; // Create a copy of the array
                                              updatedWeekSelected[5] =
                                                !updatedWeekSelected[5]; // Toggle the value of the first element
                                              setWeekSelected(
                                                updatedWeekSelected
                                              ); // Update the state with the new array
                                            }}
                                            className={classNames(
                                              weekSelected[5]
                                                ? "bg-primary-light text-dark-text"
                                                : "bg-primary rounded-full text-light-text",
                                              "w-2/12 cursor-pointer mx-2"
                                            )}
                                          >
                                            S
                                          </motion.li>
                                          <motion.li
                                            animate={{
                                              scale: weekSelected[6] ? 1.2 : 1,
                                            }}
                                            key='Sunday'
                                            onClick={() => {
                                              const updatedWeekSelected = [
                                                ...weekSelected,
                                              ]; // Create a copy of the array
                                              updatedWeekSelected[6] =
                                                !updatedWeekSelected[6]; // Toggle the value of the first element
                                              setWeekSelected(
                                                updatedWeekSelected
                                              ); // Update the state with the new array
                                            }}
                                            className={classNames(
                                              weekSelected[6]
                                                ? "bg-primary-light text-dark-text"
                                                : "bg-primary rounded-full text-light-text",
                                              "w-2/12 cursor-pointer mx-2"
                                            )}
                                          >
                                            S
                                          </motion.li>
                                        </ul>
                                      ) : null}
                                      <div>
                                        <p className='text-left mt-4'>
                                          Occurs every{" "}
                                          {days.map((item, index) => {
                                            if (
                                              weekSelected.every(
                                                (item) =>
                                                  item === false && index === 0
                                              )
                                            ) {
                                              return (
                                                <span key='everyDay'>
                                                  every day
                                                </span>
                                              );
                                            } else {
                                              return (
                                                <span key={index}>
                                                  {" "}
                                                  {weekSelected[index] ? (
                                                    <span>{item}, </span>
                                                  ) : null}
                                                </span>
                                              );
                                            }
                                          })}{" "}
                                          until{" "}
                                          <Field
                                            type='date'
                                            name='repeatEnd'
                                            id='repeatEnd'
                                            autoComplete='repeatEnd'
                                            className='mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                                          />{" "}
                                          {/* <button
                                        type='button'
                                        onClick={() => setRepeatEnd("")}
                                        className='text-primary'
                                      >
                                        Remove end date
                                      </button> */}
                                        </p>
                                      </div>
                                    </motion.div>
                                  ) : null}

                                  <div className='col-span-3 sm:col-span-2 mt-4'>
                                    <label
                                      htmlFor='location'
                                      className='block text-sm font-medium text-gray-700'
                                    >
                                      Invite attendees
                                    </label>

                                    <div>
                                      <DialogPanel className=' mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all'>
                                        <Combobox>
                                          <div className='relative'>
                                            <MagnifyingGlassIcon
                                              className='pointer-events-none absolute left-4 top-2.5 h-5 w-5 text-gray-400'
                                              aria-hidden='true'
                                            />
                                            <Combobox.Input
                                              className=' w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm focus:ring-primary focus:border-primary'
                                              placeholder='Invite attendees'
                                              onChange={(event) =>
                                                setAttendeesQuery(
                                                  event.target.value
                                                )
                                              }
                                            />
                                          </div>

                                          {filteredPeople.length > 0 && (
                                            <ComboboxOptions
                                              static
                                              className='z-10 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800'
                                            >
                                              {filteredPeople.map((person) => (
                                                <ComboboxOption
                                                  key={person.id}
                                                  value={person}
                                                  type='button'
                                                  onClick={() =>
                                                    addPerson(person)
                                                  }
                                                  className={({ active }) =>
                                                    classNames(
                                                      "cursor-default select-none px-4 py-2",
                                                      active &&
                                                        "bg-primary text-white"
                                                    )
                                                  }
                                                >
                                                  {person.name}
                                                </ComboboxOption>
                                              ))}
                                            </ComboboxOptions>
                                          )}

                                          {attendeesQuery !== "" &&
                                            filteredPeople.length === 0 && (
                                              <p
                                                className='p-4 text-sm font-bold text-gray-500'
                                                onClick={() =>
                                                  addPerson({
                                                    id: personList.length + 1,
                                                    email: attendeesQuery,
                                                    name: attendeesQuery,
                                                  })
                                                }
                                              >
                                                No emails found. Click to add
                                              </p>
                                            )}
                                        </Combobox>
                                      </DialogPanel>
                                    </div>
                                  </div>
                                  <div className='col-span-3 sm:col-span-1 mx-2 sm:col-start-3'>
                                    <label className='block text-sm font-medium text-gray-700 mt-4'>
                                      Invited
                                    </label>
                                    {attendeesList.length !== 0 ? (
                                      <ul className='mt-4 border-2 border-dark-text rounded-lg max-h-44 overflow-y-auto'>
                                        {attendeesList.map((person) => (
                                          <li
                                            key={person.email}
                                            className='flex m-2'
                                          >
                                            <p
                                              onClick={() =>
                                                removePerson(person.email)
                                              }
                                              className='text-md text-dark-text font-semibold text-center cursor-pointer'
                                            >
                                              {person.email}
                                            </p>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </div>

                                  <div className='col-span-2 mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                                    <button
                                      type='submit'
                                      className='inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-dark-text shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light sm:col-start-2'
                                      onClick={() => resetCreateEvent()}
                                    >
                                      Create Event
                                    </button>

                                    <button
                                      type='button'
                                      className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0'
                                      onClick={() => resetCreateEvent()}
                                      ref={cancelButtonRef}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/* Step 4 */}
        <div className='xxxl:col-span-full bg-light-text border-dark-text border-2 rounded-md px-4 m-1 xl:mx-10 sm:p-5'>
          <div className='flex justify-start'>
            <CalendarIcon
              className='max-h-32 min-w-32 max-w-48 hidden sm:inline text-primary cursor-pointer'
              aria-hidden='true'
              onClick={() => {
                const element = document.querySelector("#available-div");
                if (element) {
                  element.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly
                }
              }}
            ></CalendarIcon>

            <div className='w-full sm:w-5/6 m-2'>
              <h1 className='text-2xl font-semibold'>
                Step 4: Manage Mental Health Connections
              </h1>
              <p className='m-2'>
                Consider making appointments in your calendar for these Mental
                Health Connections and schedule a time each week in your
                calendar to revisit this activity and repeat Steps 1 through 4
                to renew or create new Mental Health Connections for the
                following week.
              </p>
            </div>
          </div>
          {props.tutorialMode ? (
            <div>
              <div className='mt-3 text-center sm:mt-5'>
                <div className='flex justify-center items-center w-full h-80'>
                  <div className='w-full h-full gap-x-1.5'>
                    <ReactPlayer
                      url='https://vimeo.com/970933693'
                      controls
                      width='100%'
                      height='100%'
                      className='min-h-80'
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {/* Completed MHCs */}
        <div className='md:col-span-full border-2 border-dark-text rounded-lg bg-light-text px-4 xl:mx-10 py-5 sm:px-6'>
          <h3 className='text-lg font-semibold leading-6'>
            MHCs available to mark as completed
          </h3>
          <div className='-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-2'>
              <input
                type='text'
                className='rounded m-2 w-full'
                placeholder='Search'
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <ul className='m-4 mt-2 border-dark-text grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 xxl:grid-cols-2 gap-3 '>
            {loading ? (
              <p>Loading...</p>
            ) : (
              calendarEvents.map((event) => {
                if (
                  event.completed === false &&
                  event.scheduled === true &&
                  (search === "" || event.name.includes(search)) &&
                  event.startTime.seconds < new Date().getTime() / 1000
                ) {
                  // Increment passedEventsDisplayed count

                  return (
                    <motion.li
                      key={event.name}
                      className='flex justify-between border-2 border-dark-text gap-x-6 py-5 p-2 rounded'
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className='flex min-w-0 gap-x-4'>
                        <div className='min-w-0 flex-auto'>
                          <p className='text-md font-bold leading-6 text-gray-900'>
                            {event.name}
                          </p>
                          {event.allDay === true ? (
                            <p className='mt-1 truncate text-xs leading-5 text-gray-500'>
                              All day event starting on:{" "}
                              {event.startTime
                                .toDate()
                                .toLocaleString(undefined, {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                })}
                            </p>
                          ) : (
                            <div>
                              <p className='mt-1 truncate text-md leading-5 text-gray-500'>
                                Start time:{" "}
                                {event.startTime
                                  .toDate()
                                  .toLocaleString(undefined, {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  })}
                              </p>
                              <p className='mt-1 truncate text-md leading-5 text-gray-500'>
                                End Time:{" "}
                                {event.endTime
                                  .toDate()
                                  .toLocaleString(undefined, {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  })}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='shrink-0 sm:flex sm:flex-col sm:items-end'>
                        <button
                          disabled={payment === false}
                          className='p-2 rounded-lg mt-2 bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10'
                          onClick={() => completeEvent(event)}
                        >
                          Complete
                        </button>
                      </div>
                    </motion.li>
                  );
                }
              })
            )}
          </ul>
        </div>

        {/* Upcoming MHCs */}
        <div className='md:col-span-full border-2 border-dark-text rounded-lg bg-light-text px-4 xl:mx-10 py-5 sm:px-6'>
          <div className='flex justify-between'>
            <h3 className='text-lg pb-4 font-semibold leading-6 text-dark-text'>
              Upcoming MHCs
            </h3>
            <div className='flex items-end flex-col w-1/2'>
              <button className='p-2 rounded-lg bg-primary text-light-text hover:bg-primary-light hover:text-dark-text '>
                <Link
                  to='/dashboard'
                  state={{
                    section: "Calendar Subscriptions",
                    user: props.userID,
                    formSection: "Create Connections",
                  }}
                >
                  Calendar Subscriptions
                </Link>
              </button>
            </div>
          </div>
          <ul className='divide-y divide-dark-text border-t-2 mt-2 border-dark-text'>
            {loading ? (
              <p>Loading...</p>
            ) : (
              calendarEvents
                .filter((event) => {
                  return (
                    event.completed === false &&
                    event.scheduled === true &&
                    event.startTime.seconds >= new Date().getTime() / 1000
                  );
                })
                .sort((a, b) => a.startTime.seconds - b.startTime.seconds) // Sort events by startTime
                .slice(0, 5) // Limit to the first 5 events

                .map((event) => {
                  return (
                    <motion.li
                      key={event.name}
                      className='flex justify-between gap-x-6 py-2'
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className='flex min-w-0 gap-x-4'>
                        <div className='min-w-0 flex-auto'>
                          <p className='text-md font-semibold leading-6 text-gray-900'>
                            {event.name}
                          </p>
                          {event.allDay === true ? (
                            <p className='mt-1 truncate text-xs leading-5 text-gray-500'>
                              All day event starting on:{" "}
                              {event.startTime
                                .toDate()
                                .toLocaleString(undefined, {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                })}
                            </p>
                          ) : (
                            <div>
                              <p className='mt-1 truncate text-xs leading-5 text-gray-500'>
                                Start time:{" "}
                                {event.startTime
                                  .toDate()
                                  .toLocaleString(undefined, {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  })}
                              </p>
                              <p className='mt-1 truncate text-xs leading-5 text-gray-500'>
                                End Time:{" "}
                                {event.endTime
                                  .toDate()
                                  .toLocaleString(undefined, {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  })}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='shrink-0 grid grid-cols-1 md:grid-cols-2 md:gap-x-4 mx-1 ml-auto '>
                        <button
                          className='p-2 rounded-lg mt-2 bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10 sm:mr-4'
                          onClick={() => selectConnection(event)}
                        >
                          Modify
                        </button>
                        <button
                          className='p-2 rounded-lg mt-2 bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10'
                          onClick={() => cancelEvent(event)}
                        >
                          Cancel
                        </button>
                      </div>
                    </motion.li>
                  );
                })
            )}
          </ul>
        </div>

        <div className='md:col-span-full grid grid-cols-1 md:grid-cols-2 gap-4'>
          {/* Available MHCs */}
          <div
            id='available-div'
            className=' border-2 border-dark-text bg-light-text rounded-lg px-4 xl:ml-10 py-5'
          >
            <h2 className='p-4 text-lg font-semibold border-b-2 border-dark-text'>
              Available MHCs
            </h2>
            <div className='-ml-4 -mt-2 mx-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
              <div className='ml-8 mt-2'>
                <input
                  type='text'
                  className='rounded m-2 w-full'
                  placeholder='Search available MHCs'
                  onChange={(e) => setSearchConnections(e.target.value)}
                />
              </div>
            </div>

            <ul className='m-2 mt-2 border-dark-text grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xxxxl:grid-cols-2 gap-3'>
              {loading ? (
                <p>Loading...</p>
              ) : (
                calendarEvents.map((info, index) => {
                  if (
                    connectionsDisplayed < 6 &&
                    info.name
                      .toLowerCase()
                      .includes(searchConnections.toLowerCase()) &&
                    info.scheduled === false
                  ) {
                    connectionsDisplayed++;

                    return (
                      <motion.li
                        key={info.name}
                        className=' flex items-center justify-between p-2 border-2 border-dark-text rounded'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <div>
                          <h3 className='m-2'>{info.name}</h3>
                          {info.sectionList === undefined ||
                          info.sectionList.length === 0 ? null : (
                            <NonagoneMap
                              className='max-h-20 w-auto my-auto'
                              data={info.sectionList}
                            />
                          )}
                        </div>
                        <div className='flex justify-between'>
                          <div className='flex flex-col sm:mx-1 ml-auto '>
                            <button
                              disabled={payment === false}
                              onClick={() => selectConnection(info)}
                              className='mb-2 p-2 rounded-lg text-light-text max-h-20'
                            >
                              <img
                                alt='Create Event Button'
                                src={createEventButton}
                                className='max-h-20 w-auto hover:bg-gray-200 rounded-lg '
                              />
                            </button>
                            <button
                              disabled={payment === false}
                              className='mt-2 p-2 rounded-lg bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10'
                              onClick={() => completeEvent(info)}
                            >
                              Complete
                            </button>
                          </div>
                        </div>
                      </motion.li>
                    );
                  }
                })
              )}
            </ul>
          </div>
          {/* most frequent MHCs */}
          <div
            id='most frequent'
            className='border-2 border-dark-text bg-light-text rounded-lg px-4 xl:mr-10 py-5 mt-4 md:mt-0 sm:px-6'
          >
            <h2 className='p-4 text-lg font-semibold border-b-2 border-dark-text'>
              Most frequent MHCs
            </h2>
            <ul className='mt-2 border-dark-text grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xxxxl:grid-cols-2 gap-3'>
              {loading ? (
                <p>Loading...</p>
              ) : (
                sortedEvents.map((info, index) => {
                  // if the event is in the most frequent list
                  if (info.scheduled === false) {
                    return (
                      <motion.li
                        key={info.name}
                        className=' flex items-center justify-between p-2 border-2 border-dark-text rounded'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <div>
                          <h3 className='m-2'>{info.name}</h3>
                          {info.sectionList === undefined ||
                          info.sectionList.length === 0 ? null : (
                            <NonagoneMap
                              className='max-h-20 w-auto my-auto'
                              data={info.sectionList}
                            />
                          )}
                        </div>
                        <div className='flex justify-between'>
                          <div className='flex flex-col sm:mx-1 ml-auto '>
                            <button
                              disabled={payment === false}
                              onClick={() => selectConnection(info)}
                              className='mb-2 p-2 rounded-lg text-light-text max-h-20'
                            >
                              <img
                                alt='Create Event Button'
                                src={createEventButton}
                                className='max-h-20 w-auto hover:bg-gray-200 rounded-lg '
                              />
                            </button>
                            <button
                              disabled={payment === false}
                              className='mt-2 p-2 rounded-lg bg-primary text-light-text hover:bg-primary-light hover:text-dark-text max-h-10'
                              onClick={() => completeEvent(info)}
                            >
                              Complete
                            </button>
                          </div>
                        </div>
                      </motion.li>
                    );
                  }
                  return null;
                })
              )}
            </ul>
          </div>
        </div>
      </div>

      {/* Reminder sections */}
      <div className='md:col-span-full border-2 border-dark-text rounded-lg bg-light-text px-4 xl:mx-10 py-5 sm:px-6 my-4'>
        <h2 className='p-4 text-lg font-semibold border-b-2 border-dark-text'>
          Reminders
        </h2>

        <Formik
          initialValues={{
            dailyReminder: calendarReminders[0].active || false,
            dailyReminderTime: calendarReminders[0].reminderTime
              ? calendarReminders[0].reminderTime
                  .toDate()
                  .toString()
                  .split(" ")[4]
                  .substring(0, 5)
              : new Date().toString().split(" ")[4].substring(0, 5),
            weeklyReminder: calendarReminders[1].active || false,
            weeklyReminderTime: calendarReminders[1].reminderTime
              ? calendarReminders[1].reminderTime
                  .toDate()
                  .toString()
                  .split(" ")[4]
                  .substring(0, 5)
              : new Date().toString().split(" ")[4].substring(0, 5),
            dayOfWeek: "Sunday",
          }}
          onSubmit={async (values) => {
            setOpenReminder(true);
            // check for daily reminder and weekly reminder
            // if daily reminder, create a reminder for every day
            let dailyReminder = "";
            let weeklyReminder = "";

            if (values.dailyReminder === true) {
              let dailyReminderTime = values.dailyReminderTime.split(":");
              let tempTime = new Date();
              tempTime.setHours(dailyReminderTime[0]);
              tempTime.setMinutes(dailyReminderTime[1]);

              var dailyReminderTime2 = Timestamp.fromDate(tempTime); // the time for the reminder
            }
            // if weekly reminder, create a reminder for every week
            if (values.weeklyReminder === true) {
              let weeklyReminderTime = values.weeklyReminderTime.split(":");
              let tempTime = new Date();
              tempTime.setHours(weeklyReminderTime[0]);
              tempTime.setMinutes(weeklyReminderTime[1]);
              let tempDay = values.dayOfWeek;
              let dayTell = 0;
              if (tempDay === "Sunday") {
                dayTell = 7 - tempTime.getDay();
              } else if (tempDay === "Monday") {
                dayTell = 1 - tempTime.getDay();
              } else if (tempDay === "Tuesday") {
                dayTell = 2 - tempTime.getDay();
              } else if (tempDay === "Wednesday") {
                dayTell = 3 - tempTime.getDay();
              } else if (tempDay === "Thursday") {
                dayTell = 4 - tempTime.getDay();
              } else if (tempDay === "Friday") {
                dayTell = 5 - tempTime.getDay();
              } else if (tempDay === "Saturday") {
                dayTell = 6 - tempTime.getDay();
              }
              // set to next week if the day is before the current day
              if (dayTell <= 0) {
                dayTell = dayTell + 7;
              }
              tempTime.setDate(tempTime.getDate() + dayTell);
              var weeklyReminderTime2 = Timestamp.fromDate(tempTime); // the weekly time reminder
            }

            // check if the reminder already exists
            // if it does, update it
            const docRef = await getDoc(
              doc(db, "User/" + props.userID + "/Calendar", calendarID)
            );
            const docInfo = docRef.data();
            if (docInfo.reminders) {
              let reminderInfo = docInfo.reminders;
              // if reminder exists update the time and the status
              for (const element of reminderInfo) {
                let reminder = element;
                if (reminder.name === "daily") {
                  reminder.reminderTime = dailyReminderTime2;
                  reminder.active = values.dailyReminder;
                  updateDoc(
                    doc(db, "User/" + props.userID + "/Calendar", calendarID),
                    {
                      reminders: reminderInfo,
                    }
                  );
                } else if (reminder.name === "weekly") {
                  reminder.reminderTime = weeklyReminderTime2;
                  reminder.active = values.weeklyReminder;
                  updateDoc(
                    doc(db, "User/" + props.userID + "/Calendar", calendarID),
                    {
                      reminders: reminderInfo,
                    }
                  );
                }
              }
            } else {
              // for new users so it creates the reminders in the backend
              let reminderInfo = [];
              if (dailyReminder) {
                reminderInfo.push({
                  name: "daily",
                  reminderTime: dailyReminder,
                  active: values.dailyReminder,
                });
              }
              if (weeklyReminder) {
                reminderInfo.push({
                  name: "weekly",
                  reminderTime: weeklyReminder,
                  active: values.weeklyReminder,
                });
              }

              updateDoc(
                doc(db, "User/" + props.userID + "/Calendar", calendarID),
                {
                  reminders: reminderInfo,
                }
              );
            }

            // if it doesn't, create it
          }}
        >
          <Form className=''>
            <div className='m-4 text-center mx-auto'>
              <ul className='content-center grid grid-cols-2'>
                <li className='m-2 w-full mx-auto border-r-2 border-dark-text'>
                  <label
                    htmlFor='date'
                    className=' text-md font-medium text-gray-700 m-2'
                  >
                    Daily reminder
                  </label>
                  <Field
                    type='checkbox'
                    name='dailyReminder'
                    id='dailyReminder'
                    autoComplete='dailyReminder'
                    className='mt-1 focus:ring-primary focus:border-primary shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                  <br />
                  <Field
                    type='time'
                    name='dailyReminderTime'
                    id='dailyReminderTime'
                    className='mt-1 focus:ring-primary focus:border-primary  shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                  <ul className='text-left list-disc mx-auto pl-8 pr-2 pt-2'>
                    <li>Create any new MHCs coming up</li>
                    <li>
                      Add any new MHCs that happened today that were initially
                      unplanned
                    </li>
                    <li>Indicate completed MHCs for your 'Achievement' bar</li>
                  </ul>
                </li>
                <li className='m-2 w-full mx-auto'>
                  <label
                    htmlFor='date'
                    className=' text-md font-medium text-gray-700 m-2'
                  >
                    Weekly reminder
                  </label>
                  <Field
                    type='checkbox'
                    name='weeklyReminder'
                    id='weeklyReminder'
                    autoComplete='weeklyReminder'
                    className='mt-1 focus:ring-primary focus:border-primary  shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                  <br />

                  <Field
                    type='time'
                    step='900'
                    name='weeklyReminderTime'
                    id='weeklyReminderTime'
                    className='mt-1 focus:ring-primary focus:border-primary  shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                  <Field as='select' name='dayOfWeek' className='m-2'>
                    <option value='Sunday'>Sunday</option>
                    <option value='Monday'>Monday</option>
                    <option value='Tuesday'>Tuesday</option>
                    <option value='Wednesday'>Wednesday</option>
                    <option value='Thursday'>Thursday</option>
                    <option value='Friday'>Friday</option>
                    <option value='Saturday'>Saturday</option>
                  </Field>
                  <ul className='text-left list-disc mx-auto pl-8 pr-2 pt-2'>
                    <li>
                      Complete the weekly self-assessment of your Mental Health
                      and the Health Circles
                    </li>
                    <li>Create any new MHCs that are coming up</li>
                  </ul>
                </li>
              </ul>

              <button
                disabled={payment === false}
                type='submit'
                className='bg-primary text-light-text font-semibold rounded-lg px-4 py-2 m-4'
              >
                Set reminders
              </button>
            </div>
          </Form>
        </Formik>
      </div>

      {/* Stats Section */}
      <div
        key='stats'
        className=' sm:col-span-full divide-2 mt-4 divide-gray-200 rounded-lg bg-white shadow border-2 border-black '
      >
        <div>
          <div className='-mt-px flex divide-x-2 divide-gray-200'>
            <div className='bg-gray-900 w-full'>
              <div className='mx-auto max-w-full'>
                <div className='grid grid-cols-1 gap-px bg-white sm:grid-cols-2 lg:grid-cols-4 '>
                  <div
                    key='number of weekly assessments'
                    className='bg-gray-900 px-4 py-4 sm:px-6 lg:px-8'
                  >
                    <p className='text-sm font-medium leading-6 text-gray-400'>
                      Number of Weekly Assessments
                    </p>
                    <p className='mt-2 flex items-baseline gap-x-2'>
                      <span className='text-xl font-semibold tracking-tight text-white'>
                        {numberOfAssessments}
                      </span>
                    </p>
                  </div>
                  <div
                    key='number of completed MH Connections'
                    className='bg-gray-900 px-4 py-4 sm:px-6 lg:px-8'
                  >
                    <p className='text-sm font-medium leading-6 text-gray-400'>
                      Completed MHCs
                    </p>
                    <p className='mt-2 flex items-baseline gap-x-2'>
                      <span className='text-xl font-semibold tracking-tight text-white'>
                        {userInfo.number_completed}
                      </span>
                    </p>
                  </div>
                  <div
                    key='next achievement'
                    className='bg-gray-900 px-4 py-4 p-2 sm:px-6 lg:px-8'
                  >
                    <p className='text-sm font-medium leading-6 text-gray-400'>
                      Next Achievement
                    </p>
                    <p className='mt-2 flex items-baseline gap-x-2'>
                      <span className='text-xl font-semibold tracking-tight text-white'>
                        {nextAchievement}
                      </span>
                    </p>
                  </div>
                  <div
                    key='assessment streak'
                    className='bg-gray-900 px-4 py-4 sm:px-6 lg:px-8'
                  >
                    <p className='text-sm font-medium leading-6 text-gray-400'>
                      Assessment Streak{" "}
                    </p>
                    <p className='mt-2 flex items-baseline gap-x-2'>
                      <span className='text-xl font-semibold tracking-tight text-white'>
                        1
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.page === "FormDashboard" ? (
        <div className='flex justify-between'>
          <Link
            to='/dashboard'
            state={{
              section: "Self Assessment",
              formSection: "Create Connections",
            }}
            className='bg-primary  text-light-text font-semibold rounded-lg px-4 py-2 m-4 hover:bg-primary-light hover:text-dark-text max-h-10 min-w-button text-center'
          >
            Back
          </Link>

          <Link
            onClick={() => saveForm()}
            className='bg-primary  text-light-text font-semibold rounded-lg px-4 py-2 m-4 hover:bg-primary-light hover:text-dark-text max-h-10 min-w-button text-center'
          >
            Complete Assessment
          </Link>
        </div>
      ) : (
        <div className='flex justify-between'>
          <Link
            to='/dashboard'
            state={{
              section: "Create MH Connections",
            }}
            className='bg-primary text-light-text font-semibold rounded-lg px-4 py-2 m-4 hover:bg-primary-light hover:text-dark-text max-h-10 min-w-button text-center'
          >
            Back
          </Link>
        </div>
      )}
    </motion.div>
  ) : null;
}
