import React, { useState } from "react";
import { Formik, Form } from "formik";
import RadarChart from "./Charts/RadarChart";
import PropTypes from "prop-types";

export default function OverallMentalHealthData(props) {
  OverallMentalHealthData.propTypes = {
    userID: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
  };

  const [timePeriod] = useState("year");

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div className='col-span-full bg-light-text p-2 rounded-lg shadow-xl'>
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          //console.log(values);
        }}
      >
        <Form className='flex w-full flex-col'>
          <div className='grid grid-cols-1 sm:grid-cols-2  justify-between gap-x-6 py-5 px-2'>
            <h1 className='p-4 truncate text-xl font-medium text-dark-text'>
              Overall Progress tracker
              <p className='text-md text-gray-600'>
                {" "}
                {monthNames[props.date.getMonth()]} {props.date.getFullYear()}
              </p>
            </h1>
          </div>
          {/* information div */}
          <div className='w-full sm:w-1/2 mx-auto'>
            <RadarChart userID={props.userID} timePeriod={timePeriod} />
          </div>
        </Form>
      </Formik>
    </div>
  );
}
